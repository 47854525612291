// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { GeographyStoreXhr } from './geography-store.xhr';

const ENTITY = 'GEOGRAPHY_STORE';

export interface GeographyStore extends IsaiaEntity<typeof ENTITY> {
  id: string;
  category: string;
  cityName: string;
  code: string;
  countryName: string;
  countryCode: string;
  currency: string;
  language: string;
  name: string;
  status: string;
  timezone: string;
  type: string;
  xyId: string;
  area: string;
  priceList?: string;
}

export function createGeographyStore(options: Partial<GeographyStoreXhr>): GeographyStore {
  return markIsaiaEntity(ENTITY, {
    id: `${options.id}`,
    category: options.category,
    cityName: options.city,
    code: options.code,
    countryName: options.country,
    countryCode: options.countryCode,
    currency: options.currency,
    language: options.language,
    name: options.name,
    status: options.status,
    timezone: options.timezone,
    type: options.type,
    xyId: options.xyId,
    area: options.zona,
    priceList: options.priceList,
  });
}

export function isInstanceOfGeographyStore(value: unknown): value is GeographyStore {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
