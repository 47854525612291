export enum ApparelCategorySlug {
  Vest = 'GT_vest',
  Suit = 'A_suit',
  Trouser = 'PN_trouser',
  Jacket = 'G_jacket',
  Overcoat = 'CP_overcoat',
  Shirt = 'CM_shirt',
  Tuxedo = 'SK_tuxedo',
  TuxedoJacket = 'GS_tuxedo_jacket',
  TuxedoShirt = 'CM_tuxedo_shirt',
}

export type ApparelCategorySlugMtm =
  | ApparelCategorySlug.Suit
  | ApparelCategorySlug.Jacket
  | ApparelCategorySlug.Overcoat
  | ApparelCategorySlug.Shirt
  | ApparelCategorySlug.Vest
  | ApparelCategorySlug.Trouser;
