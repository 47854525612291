import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEASUREMENT_API_ORIGIN } from './measurement-api-origin.token';
import {
  HttpGetOptions,
  HttpPatchOptions,
  HttpPostOptions,
  HttpPutOptions,
  HttpResponse,
  HttpResponseWithPagination,
} from '@isaia/entity/http';
import { map } from 'rxjs';
import { createId } from '@isaia/id';
import { createMeasurement, Measurement, MeasurementStatus, MeasurementXhr, StaticMeasurements } from '@isaia/entity/measurement';
import { ensureNumber } from '@isaia/number';
import { OverrideProperties, SetRequired } from 'type-fest';

export enum GetMeasurementsParams {
  FilterCustomerId = 'filter[customerId]',
  FilterCategoryId = 'filter[categoryId]',
  FilterType = 'filter[type]',
  FilterStatus = 'filter[status]',
}

type HttpGetMeasurementsStaticResponse = HttpResponse<StaticMeasurements>;

type HttpGetMeasurementsResponse = HttpResponseWithPagination<MeasurementXhr[]>;
export type HttpGetMeasurementsOptions = HttpGetOptions<Partial<Record<GetMeasurementsParams, string | number | null>>>;

export type HttpCreateMeasurementData = SetRequired<
  Partial<OverrideProperties<Measurement, { categoryId: Measurement['categoryId'] | string }>>,
  'customerId' | 'categoryId' | 'type' | 'data'
>;
type HttpCreateMeasurementResponse = HttpResponseWithPagination<MeasurementXhr[]>;

export type HttpUpdateMeasurementData = {
  measurement: Measurement;
  updateWith: Measurement['data'];
};
type HttpUpdateMeasurementResponse = HttpResponseWithPagination<MeasurementXhr[]>;

export type HttpUpdateMeasurementStatusData = { measurementId: string; status: MeasurementStatus };

@Injectable()
export class MeasurementApiService {
  private readonly http = inject(HttpClient);
  private readonly API_ORIGIN = inject(MEASUREMENT_API_ORIGIN);

  public getStaticMeasurements(options?: HttpGetOptions) {
    return this.http
      .get<HttpGetMeasurementsStaticResponse>(`${this.API_ORIGIN}/values`, options as unknown as HttpGetOptions)
      .pipe(map((response) => response.data));
  }

  public getMeasurements(options?: HttpGetMeasurementsOptions) {
    return this.http.get<HttpGetMeasurementsResponse>(this.API_ORIGIN, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return {
          pagination: { ...response.pagination },
          measurements: response.data.map(createMeasurement),
        };
      }),
    );
  }

  public createMeasurement(data: HttpCreateMeasurementData, options?: HttpPostOptions) {
    const measurement: Omit<MeasurementXhr, 'createdAt'> = {
      id: createId(),
      customerId: data.customerId,
      categoryId: ensureNumber(data.categoryId),
      data: data.data,
      type: data.type,
      status: MeasurementStatus.Active,
    };
    return this.http
      .post<HttpCreateMeasurementResponse>(this.API_ORIGIN, measurement, options as unknown as HttpPostOptions)
      .pipe(map((res) => createMeasurement(res.data[0])));
  }

  public updateMeasurement({ measurement, updateWith }: HttpUpdateMeasurementData, options?: HttpPutOptions) {
    const payload: Omit<MeasurementXhr, 'createdAt'> = {
      id: measurement.id,
      customerId: measurement.customerId,
      categoryId: ensureNumber(measurement.categoryId),
      type: measurement.type,
      status: measurement.status,
      data: updateWith,
    };
    return this.http
      .put<HttpUpdateMeasurementResponse>(`${this.API_ORIGIN}/${measurement.id}`, payload, options as unknown as HttpPutOptions)
      .pipe(map((res) => createMeasurement(res.data[0])));
  }

  public updateStatus(data: HttpUpdateMeasurementStatusData, options?: HttpPatchOptions) {
    return this.http
      .patch<HttpUpdateMeasurementResponse>(
        `${this.API_ORIGIN}/${data.measurementId}/status`,
        { status: data.status },
        options as unknown as HttpPatchOptions,
      )
      .pipe(map((res) => createMeasurement(res.data[0])));
  }
}
