<ng-content select="[isaModalHeader]"></ng-content>

@if (hasHeader()) {
  <div class="isa-modal__header">
    <div class="isa-modal__header-title" [innerHTML]="title()"></div>
    @if (hasClosableHeader()) {
      <isa-button-cancel (click)="closeHeader()" />
    }
  </div>
}

<div class="isa-modal__content">
  <ng-content></ng-content>
</div>
