// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { Simplify } from 'type-fest';
import { createEntityInternalizationLabel, Internationalization } from '@isaia/entity/internationalization';
import { ApparelCategoryTaxonomyXhr } from './apparel-category-taxonomy.xhr';

export type ApparelCategoryTaxonomyAttribute = Simplify<{ id: string } & Internationalization>;
type ApparelCategoryTaxonomyAttributeXhr = Partial<ApparelCategoryTaxonomyXhr['attributes'][number]> & { slug: string };

function createApparelCategoryTaxonomyAttribute(
  options?: Partial<ApparelCategoryTaxonomyAttributeXhr> | Partial<ApparelCategoryTaxonomyAttribute>,
): ApparelCategoryTaxonomyAttribute {
  const fromApi = options && 'code' in options ? (options as Partial<ApparelCategoryTaxonomyAttributeXhr>) : undefined;
  const fromApp = options && 'id' in options ? (options as Partial<ApparelCategoryTaxonomyAttribute>) : undefined;

  return {
    id: fromApi?.code || fromApp?.id || '',
    slug: fromApi?.slug || fromApp?.slug || '',
    label: createEntityInternalizationLabel(fromApi?.label || fromApp?.label),
  };
}

const ENTITY = 'APPAREL_CATEGORY_TAXONOMY';

export interface ApparelCategoryTaxonomy extends IsaiaEntity<typeof ENTITY>, Internationalization {
  id: number | string;
  attributes: ApparelCategoryTaxonomyAttribute[];
  isTaxonomyMandatory: boolean;
  isTaxonomyNeededToCalculatePrice: boolean;
  isVisibleInMeasurement: boolean;
  measurementType: string;
  order: number;
  group: Internationalization & { order?: number };
}

export function createApparelCategoryTaxonomy(
  options?: Partial<ApparelCategoryTaxonomyXhr> | Partial<ApparelCategoryTaxonomy>,
): ApparelCategoryTaxonomy {
  const fromApi = options && 'taxonomySlug' in options ? (options as Partial<ApparelCategoryTaxonomyXhr>) : undefined;
  const fromApp = options && 'slug' in options ? (options as Partial<ApparelCategoryTaxonomy>) : undefined;

  const id = fromApi ? fromApi?.id : fromApp?.id;
  const slug = fromApi ? fromApi?.taxonomySlug : fromApp?.slug;
  const label = fromApi ? fromApi?.taxonomyLabels : fromApp?.label;
  const attributes = fromApi ? fromApi?.attributes : fromApp?.attributes;
  const isTaxonomyMandatory = fromApi ? fromApi?.isTaxonomyMandatory : fromApp?.isTaxonomyMandatory;
  const isTaxonomyNeededToCalculatePrice = fromApi ? fromApi?.isTaxonomyNeededToCalculatePrice : fromApp?.isTaxonomyNeededToCalculatePrice;
  const isVisibleInMeasurement = fromApi ? fromApi?.isVisibleInMeasurementType : fromApp?.isVisibleInMeasurement;
  const measurementType = fromApi ? fromApi?.measurementType : fromApp?.measurementType;
  const order = fromApi ? fromApi.taxonomyPriority : fromApp?.order;
  const groupSlug = fromApi ? fromApi?.taxonomyGroup?.taxonomyGroupSlug : fromApp?.group?.slug;
  const groupLabel = fromApi ? fromApi?.taxonomyGroup?.taxonomyGroupLabels : fromApp?.group?.label;
  const groupOrder = fromApi ? fromApi?.taxonomyGroup?.taxonomyGroupPriority : fromApp?.group?.order;

  return markIsaiaEntity(ENTITY, {
    id: id || NaN,
    slug: slug || '',
    label: createEntityInternalizationLabel(label),
    attributes: attributes?.map(createApparelCategoryTaxonomyAttribute) || [],
    isTaxonomyMandatory: !!isTaxonomyMandatory,
    isTaxonomyNeededToCalculatePrice: !!isTaxonomyNeededToCalculatePrice,
    isVisibleInMeasurement: !!isVisibleInMeasurement,
    measurementType: measurementType || '',
    order,
    group: {
      slug: groupSlug || '',
      label: createEntityInternalizationLabel(groupLabel),
      order: groupOrder,
    },
  });
}

export function isInstanceOfApparelCategoryTaxonomy(value: unknown): value is ApparelCategoryTaxonomy {
  return isIsaiaEntity(value, ENTITY);
}
