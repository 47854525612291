import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  viewChild,
  ViewEncapsulation,
  untracked,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { injectNgControl, NoopValueAccessorDirective } from '@isaia/components/control-value-accessor';
import { FormFieldErrorComponent, FormFieldErrorCustom } from '@isaia/components/form-field-error';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createDisabledWatcher, createErrorWatcher } from '@isaia/components/form-interaction';
import { merge } from 'rxjs';

@Component({
  selector: 'isa-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  hostDirectives: [NoopValueAccessorDirective],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, FormFieldErrorComponent],
  host: {
    '[attr.disabled]': 'disabled.$isDisabled()',
  },
})
export class InputComponent {
  private readonly destroyRef = inject(DestroyRef);
  public readonly ngControl = injectNgControl();
  public readonly error = createErrorWatcher();
  public readonly disabled = createDisabledWatcher();

  public inputRef = viewChild('inputRef', { read: ElementRef });
  public type = input<'text' | 'email' | 'number' | 'password' | 'date' | 'datetime-local' | 'tel'>('text');
  public placeholder = input<string | undefined>('');
  public name = input<string | undefined>('');
  public autocomplete = input('off');
  public autocorrect = input('off');
  public autocapitalize = input('off');
  public spellcheck = input('false');
  public pattern = input<string | RegExp | undefined>();
  public customErrors = input<FormFieldErrorCustom | undefined>();
  public showErrors = input<boolean>(true);

  constructor() {
    effect(() => {
      // serve per valorizzare il disabled al bootstrap del componente
      this.disabled.$isDisabled();
      const showErrors = !!this.showErrors();
      untracked(() => this.error.isVisible$.next(showErrors));
    });
    afterNextRender(() => {
      const ctrl = this.ngControl;
      merge(this.disabled.watch$(ctrl), this.error.watch$(ctrl)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    });
  }
}
