import { computed, inject, Injectable, signal } from '@angular/core';
import {
  ApparelCategoriesKeyed,
  ApparelCategory,
  ApparelCategorySlug,
  isInstanceOfApparelCategory,
  STATIC_TAXONOMIES,
} from '@isaia/entity/apparel';
import { capitalize, keyBy } from 'lodash-es';
import { SessionProduct } from '@isaia/entity/session';
import { getComputedModelId, isCategoryWithComputedCode } from '@isaia/apparel';
import { MeasurementRepository } from '../measurement';
import { createMeasurementSlug } from '@isaia/measurement';
import { translateApi } from '@isaia/i18n/language';

type CategoryIdOrCategory = ApparelCategory['id'] | string | ApparelCategory;
type GetTaxonomiesOptions = { omitStaticTaxonomies?: boolean };

export interface ApparelCategoryWithLabel {
  label: string;
  category: ApparelCategory;
}

export function addAreaToCategoryName(categoryNameTranslated: string, options: { showArea?: boolean; area: string }) {
  return options.showArea ? `${categoryNameTranslated} - ${capitalize(options.area)}` : categoryNameTranslated;
}

@Injectable()
export class ApparelRepository {
  private readonly measurementRepository = inject(MeasurementRepository);
  private readonly state = {
    $categories: signal([] as ApparelCategory[]),
  };

  public $categories = this.state.$categories.asReadonly();
  public $categoriesKeyedById = computed(() => keyBy(this.$categories(), 'id') as ApparelCategoriesKeyed);

  public categoriesKeyedBySectionKeyedByArea = computed(() => {
    return this.$categories().reduce<{ [area: string]: { [category: string]: ApparelCategory[] } }>((acc, cat) => {
      const slug = cat.section.slug;
      const area = cat.section.area;
      const categories = [...(acc[area]?.[slug] || []), cat];
      acc[area] = { ...acc[area], [slug]: categories };
      return acc;
    }, {});
  });

  public availableAreas = computed(() => {
    return Object.keys(this.categoriesKeyedBySectionKeyedByArea());
  });

  public getAreaImage(area: string) {
    return `assets/category/area/${area}.webp`;
  }

  public setCategories(categories: ApparelCategory[]) {
    this.state.$categories.set(categories);
  }

  public getCategoryBySlug(slug?: ApparelCategory['slug'] | string) {
    if (!slug) {
      return;
    }
    return this.$categories().find((c) => c.slug === slug);
  }

  public getCategory(id?: ApparelCategory['id'] | string) {
    if (!id) {
      return;
    }
    const ensureNumber = typeof id === 'string' ? parseInt(id) : id;
    return this.$categoriesKeyedById()[ensureNumber];
  }

  public getTaxonomyRequiredToCalculatePrice(categoryIdOrCategory?: CategoryIdOrCategory) {
    return this.getTaxonomies(categoryIdOrCategory).filter((t) => t.isTaxonomyNeededToCalculatePrice);
  }

  public getTaxonomiesVisibleInMeasurement(categorySlug?: string, measurementType?: string) {
    const categoryTaxonomies = this.getCategoryBySlug(categorySlug)?.taxonomies || [];
    const canEnrichWithExtraTaxonomies = categorySlug !== measurementType && ApparelCategorySlug.Vest === measurementType;
    const taxonomies = canEnrichWithExtraTaxonomies ? [...categoryTaxonomies, ...this.getVestTaxonomies()] : categoryTaxonomies;
    return taxonomies.filter((t) => {
      if (t.isVisibleInMeasurement) {
        return measurementType ? t.measurementType === measurementType : true;
      }
      return false;
    });
  }

  public getTaxonomies(categoryIdOrCategory?: CategoryIdOrCategory, options?: GetTaxonomiesOptions) {
    const category = this.ensureCategoryInstance(categoryIdOrCategory);
    if (!category) {
      return [];
    }
    const taxonomies = category.taxonomies;
    return options?.omitStaticTaxonomies ? taxonomies.filter((t) => !STATIC_TAXONOMIES.includes(t.slug)) || [] : taxonomies;
  }

  public getTaxonomiesByCategorySlug(categorySlug: string | ApparelCategorySlug, options?: GetTaxonomiesOptions) {
    const category = this.getCategoryBySlug(categorySlug);
    return this.getTaxonomies(category, options);
  }

  public getCategoryImage(categoryIdOrCategory?: CategoryIdOrCategory) {
    const category = this.ensureCategoryInstance(categoryIdOrCategory);
    if (!category) {
      return '';
    }
    return `assets/category/${category.section.slug}-${category.slug}.webp`;
  }

  public hasAllMandatoryTaxonomyDefined(
    categoryIdOrCategory?: CategoryIdOrCategory,
    customized?: SessionProduct['taxonomies'],
    isVestMeasurementEnabled?: boolean,
  ) {
    const category = this.ensureCategoryInstance(categoryIdOrCategory);
    if (!category) {
      return false;
    }
    const taxonomies = category.taxonomies;
    if (!taxonomies.length) {
      return true;
    }
    if (!customized || !Object.keys(customized).length) {
      return false;
    }
    const allTaxonomies = isVestMeasurementEnabled ? [...taxonomies, ...this.getVestTaxonomies()] : taxonomies;
    const measurements = this.measurementRepository.getCategoryMeasurements(category.slug);
    const hasAllTaxonomiesRequired = allTaxonomies.every((t) => (t.isTaxonomyMandatory ? !!customized[t.slug] : true));
    const hasAllMeasurementsDefined = measurements.every((m) => {
      const slug = m.category;
      const formValueSlag = createMeasurementSlug(m.category);
      const hasMeasurement = !!customized[formValueSlag];
      if (slug === ApparelCategorySlug.Vest) {
        // mock as true when is not enabled to flag as defined
        return isVestMeasurementEnabled ? hasMeasurement : true;
      }
      return hasMeasurement;
    });
    return hasAllTaxonomiesRequired && hasAllMeasurementsDefined;
  }

  public getComputedModelId<T extends object>(categoryIdOrCategory?: CategoryIdOrCategory, activeTaxonomies?: T) {
    const category = this.ensureCategoryInstance(categoryIdOrCategory);
    if (!category) {
      return '';
    }
    const slug = category.slug as ApparelCategorySlug;
    if (!isCategoryWithComputedCode(slug)) {
      return '';
    }
    const taxonomies = this.getTaxonomies(category);
    return getComputedModelId(slug, taxonomies, activeTaxonomies);
  }

  private ensureCategoryInstance(categoryIdOrCategory?: CategoryIdOrCategory) {
    return isInstanceOfApparelCategory(categoryIdOrCategory) ? categoryIdOrCategory : this.getCategory(categoryIdOrCategory);
  }

  public isCategorySuit(categoryOrSlug?: string | ApparelCategory) {
    const slug = typeof categoryOrSlug === 'string' ? categoryOrSlug : categoryOrSlug?.slug;
    return slug === ApparelCategorySlug.Suit;
  }

  public getVestTaxonomies() {
    return this.getTaxonomiesByCategorySlug(ApparelCategorySlug.Vest, { omitStaticTaxonomies: true });
  }

  public byFabricCategoryCodes(options?: { language?: string }) {
    const categories = this.$categories();
    const byCategoryCodes: Record<number, ApparelCategoryWithLabel[]> = {};
    for (const category of categories) {
      category.fabricCategoryCode.forEach((code) => {
        if (!byCategoryCodes[code]) {
          byCategoryCodes[code] = [];
        }
        const name = translateApi(category, { language: options?.language });
        const nameWithArea = addAreaToCategoryName(name, { showArea: true, area: category.section.area });
        byCategoryCodes[code].push({ label: `${nameWithArea} - ${category.slug}`, category });
      });
    }
    return byCategoryCodes;
  }
}
