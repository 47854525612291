import { createMoney, Money } from '@qwentes/money';
import { ExtraChargeStrategy, getExtraCharge } from './price-calculator.extra-charge';

export const createBespokeCalculator: ExtraChargeStrategy = (currency, byCategorySlug) => {
  const bespoke = getExtraCharge(currency, byCategorySlug);
  return {
    add(price: Money) {
      return price.sum(bespoke);
    },
    remove(price: Money) {
      const nextPrice = price.sub(bespoke);
      return nextPrice.amount >= 0 ? nextPrice : createMoney({ amount: 0, currency });
    },
  };
};
