import { inject, Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { GeographyRepository } from './geography.repository';
import { GeographyApiService, HttpGetStoresOptions } from '@isaia/geography';

@Injectable()
export class GeographyService {
  private readonly geographyApiService = inject(GeographyApiService);
  private readonly geographyRepository = inject(GeographyRepository);

  public getStores(options?: HttpGetStoresOptions) {
    return this.geographyApiService.getStores(options).pipe(
      tap((value) => {
        this.geographyRepository.setStores(value.stores);
        return value;
      }),
      catchError((e) => {
        this.geographyRepository.setStores([]);
        return throwError(() => e);
      }),
    );
  }
}
