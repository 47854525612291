import type { Internationalization } from '@isaia/entity/internationalization';
import { computed, isSignal, Signal } from '@angular/core';
import { injectLanguage } from './language.service';
import { SetOptional } from 'type-fest';

interface TranslateApiOptions {
  language?: string;
  fallback?: boolean;
}

export function translateApi<T extends SetOptional<Internationalization, 'slug'>>(entity?: T, options?: TranslateApiOptions) {
  if (!entity) {
    return '';
  }
  const { label } = entity;
  const translation = label?.[options?.language || ''] || label?.['en'];
  // if (!translation) {
  //   console.warn(`[TranslateApi]: translation not found for ${entity?.slug || JSON.stringify(entity)}`);
  // }
  return translation || (options?.fallback ? entity.slug : '') || '';
}

export type TranslateApi = <T extends { label: { [p: string]: string }; slug?: string | undefined } | undefined>(
  entity?: Signal<T> | T | undefined,
  options?: any,
) => Signal<string>;

export function injectTranslateApi(): TranslateApi {
  const $lang = injectLanguage();
  return <T extends SetOptional<Internationalization, 'slug'> | undefined>(
    entity?: T | Signal<T>,
    options?: Partial<TranslateApiOptions>,
  ) => {
    return computed(() => {
      const value = isSignal(entity) ? entity() : entity;
      return translateApi(value, {
        language: options?.language || $lang() || 'en',
        fallback: options?.fallback,
      });
    });
  };
}
