import { ChangeDetectorRef, DestroyRef, inject, NgZone, signal } from '@angular/core';
import { AbstractControl, FormGroup, NgControl } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { isEqual } from 'lodash-es';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export function isInvalidControl(control?: AbstractControl | null) {
  return !!(control?.dirty && control.invalid);
}

export function waitNgZone() {
  const ngZone = inject(NgZone);
  return {
    whenStable: <T>(fn: () => Observable<T>) => {
      return ngZone.onStable.pipe(take(1), switchMap(fn));
    },
  };
}

export function createErrorWatcher() {
  const isVisible$ = new BehaviorSubject(true);
  const $isInvalid = signal(false);
  const $errors = signal<null | object>(null);

  const watch$ = (ctrl: NgControl): Observable<boolean> => {
    if (!ctrl || !ctrl.control) {
      console.warn('Unable to create createErrorWatcher');
      return of(false);
    }

    const valueChanges$ = ctrl.control.valueChanges.pipe(startWith(ctrl.value), debounceTime(0), distinctUntilChanged());
    const statusChanges$ = ctrl.control.statusChanges?.pipe(startWith(ctrl.status), distinctUntilChanged());
    const error$ = merge(valueChanges$, statusChanges$).pipe(map(() => isInvalidControl(ctrl.control)));

    return combineLatest([error$, isVisible$]).pipe(
      map(([error, isEnabled]) => error && isEnabled),
      tap((hasErrors) => {
        $isInvalid.set(hasErrors);
        $errors.set(ctrl.errors);
      }),
    );
  };

  return {
    isVisible$,
    $isInvalid,
    $errors,
    watch$,
  };
}

export function createDisabledWatcher() {
  const $isDisabled = signal(false);

  const watch$ = (ctrl: NgControl) => {
    if (!ctrl || !ctrl.control) {
      console.warn('Unable to create createDisabledWatcher');
      return of(false);
    }

    return ctrl.control.statusChanges.pipe(
      startWith(ctrl.control.status),
      distinctUntilChanged(),
      map((value) => {
        const isDisabled = value === 'DISABLED';
        $isDisabled.set(isDisabled);
        return isDisabled;
      }),
    );
  };

  return {
    $isDisabled,
    watch$,
  };
}

export function patchDisabledInitialization(element: HTMLElement | undefined, isDisabled: () => boolean, cd: ChangeDetectorRef) {
  setTimeout(() => {
    const disabled = isDisabled();
    if (disabled) {
      element?.setAttribute('disabled', `${disabled}`);
    } else {
      element?.removeAttribute('disabled');
    }
    cd.detectChanges();
  }, 0);
}

export function patchFormUI(ctrl: AbstractControl, cd: ChangeDetectorRef, destroyRef: DestroyRef) {
  // without this patch, if we change value from another component,
  // that changes will not be reflected in the ui of this component
  // example: change some taxonomy value in view mtm-composition-summary via mtm-composition-taxonomy-list,
  // changing taxonomy value in mtm-composition-taxonomy-list will
  // not be reflected in the same taxonomy that exist in summary card
  ctrl.valueChanges
    .pipe(
      debounceTime(0),
      distinctUntilChanged(isEqual),
      tap(() => cd.markForCheck()),
      takeUntilDestroyed(destroyRef),
    )
    .subscribe();
}

export function getChangedKey<T extends Record<string, unknown>>(prev?: T, next?: T) {
  const changes = Object.keys(next || {}).filter((key) => {
    const currentValue = next?.[key];
    const prevValue = prev?.[key];
    const hasValue = !!currentValue;
    const hadValue = !!prevValue;
    const addedOrDeletedValue = (hasValue && !hadValue) || (!hasValue && hadValue);
    const updatedValue = (hasValue && currentValue) !== (hadValue && prevValue);
    return addedOrDeletedValue || updatedValue;
  });
  return changes[0] as keyof T | undefined;
}

export function getControlName(ctrl: AbstractControl) {
  const formGroup = (ctrl.parent?.controls || {}) as Record<string, AbstractControl>;
  return Object.keys(formGroup).find((name) => ctrl === formGroup[name]) || null;
}

type ErrorList = { [controlName: string]: string[] }[];

export function getErrorsFromForm(form?: FormGroup, errorsByControl: ErrorList = [], parentName?: string): ErrorList {
  if (!form) {
    return [];
  }
  for (const name in form.controls) {
    const control = form.controls[name];
    if (control instanceof FormGroup) {
      getErrorsFromForm(control, errorsByControl, name);
    } else if (control.errors) {
      const key = parentName ? `${parentName}.${name}` : name;
      errorsByControl.push({ [key]: Object.keys(control.errors) });
    }
  }
  return errorsByControl;
}
