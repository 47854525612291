import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { injectableModal, Modal, ModalComponent } from '@isaia/components/dialog';
import { InputComponent } from '@isaia/components/input';
import { FormFieldComponent } from '@isaia/components/form-field';
import { LabelComponent } from '@isaia/components/label';
import { FormBuilder, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { ButtonComponent } from '@isaia/components/button';
import { clone } from '@isaia/clone';
import { Simplify } from 'type-fest';

export interface EmailContentModalResponse {
  email: EmailContentFormValue;
}

type EmailContentModalData = Simplify<Partial<EmailContentFormValue> & { modalTitle?: string }>;

export function injectEmailContentModal() {
  const dialog = injectableModal(EmailContentModalComponent);
  return {
    open: (options?: Parameters<typeof dialog.open>[0]) => {
      return dialog.open(clone(options));
    },
  };
}

export type EmailContentFormValue = { subject: string; body: string };
type EmailContentForm = { [key in keyof EmailContentFormValue]: [EmailContentFormValue[key], ValidatorFn[]] };

@Component({
  selector: 'mtm-email-content-modal',
  templateUrl: './email-content-modal.component.html',
  styleUrls: ['./email-content-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoDirective, ModalComponent, InputComponent, FormFieldComponent, LabelComponent, ReactiveFormsModule, ButtonComponent],
})
export default class EmailContentModalComponent extends Modal<EmailContentModalResponse, EmailContentModalData> {
  public readonly form = new FormBuilder().nonNullable.group<EmailContentForm>({
    subject: [this.data.subject || '', [Validators.required]],
    body: [this.data.body || '', [Validators.required]],
  });

  public close() {
    this.dialogRef.close({ email: this.form.getRawValue() });
  }
}
