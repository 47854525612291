import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject } from '@angular/core';
import { OrderService } from './order.service';
import { injectPopupRouteResolverError } from '../router';
import { withErrorPopup } from '../http';
import { firstValueFrom } from 'rxjs';
import { ApplicationRepository } from '../application';
import { OrderItem } from '@isaia/entity/order';

export function orderItemResolver(activatedRouteSnapshot: ActivatedRouteSnapshot): Promise<OrderItem | undefined> {
  const router = inject(Router);
  const orderService = inject(OrderService);
  const applicationRepository = inject(ApplicationRepository);
  const popupRouteResolverError = injectPopupRouteResolverError();
  const orderItem = router.getCurrentNavigation()?.extras.state?.['orderItem'];
  if (orderItem) {
    return Promise.resolve(orderItem);
  }
  const orderItemId = activatedRouteSnapshot.queryParams['orderItemId'];
  if (orderItemId) {
    const request$ = orderService.getOrderItem(orderItemId, { context: withErrorPopup(false) });
    applicationRepository.setLoading(true);
    return firstValueFrom(request$)
      .catch((e) => {
        return popupRouteResolverError.open({ errorMessage: e.message, activatedRouteSnapshot });
      })
      .finally(() => {
        applicationRepository.setLoading(false);
      });
  }
  return Promise.resolve(undefined);
}
