import { inject, Injectable } from '@angular/core';
import { PriceRepository } from './price.repository';
import { HttpGetPricesOptions, PriceApiService } from '@isaia/price';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { PriceKeyed } from '@isaia/entity/price';

@Injectable()
export class PriceService {
  private readonly priceApiService = inject(PriceApiService);
  private readonly priceRepository = inject(PriceRepository);

  public getAllPriceList(options: HttpGetPricesOptions) {
    return this.priceApiService.getAllPriceList(options).pipe(
      switchMap((value) => this.validateAndSetStorePriceList(value)),
      catchError((e) => this.throwPriceError(e)),
    );
  }

  public getPriceList(priceListId?: string, options?: HttpGetPricesOptions) {
    if (!priceListId) {
      return throwError(() => 'Missing price list id');
    }
    return this.priceApiService.getPriceList(priceListId, options).pipe(
      switchMap((value) => this.validateAndSetStorePriceList(value)),
      catchError((e) => this.throwPriceError(e)),
    );
  }

  public isValidPriceList(priceListId?: string, options?: HttpGetPricesOptions) {
    if (!priceListId) {
      return throwError(() => 'Missing price list id');
    }
    return this.priceApiService.getPriceList(priceListId, options).pipe(
      switchMap((res) => {
        const isValid = this.hasValidPrice(res) && !!this.priceRepository.getCurrency(res[priceListId]);
        return isValid ? of(true) : throwError(() => `Invalid price list for ${priceListId}`);
      }),
    );
  }

  private hasValidPrice(priceKeyed: PriceKeyed) {
    return Object.keys(priceKeyed).length > 0;
  }

  private validateAndSetStorePriceList(priceKeyed: PriceKeyed) {
    if (this.hasValidPrice(priceKeyed)) {
      this.priceRepository.setAllPriceList(priceKeyed);
      return of(priceKeyed);
    }
    return throwError(() => 'Invalid prices, please contact the assistance.');
  }

  private throwPriceError(error: Error | string) {
    const message = typeof error === 'string' ? error : error.message;
    alert(message);
    this.priceRepository.setAllPriceList(undefined);
    return throwError(() => error);
  }
}
