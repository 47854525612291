import { computed, inject, Injectable, signal } from '@angular/core';
import { GeographyStore } from '@isaia/entity/geography';
import { AuthRepository } from '../auth';
import { isEqual, keyBy } from 'lodash-es';
import { getGeographyStoreName } from '@isaia/geography';

@Injectable()
export class GeographyRepository {
  #LEGAL: Record<string, string[]> = {
    us: ['Isaia Corporation', '257 Park Avenue South, 10 FLR,  New York, NY 10010', 'New York, USA', '27-0128103'],
    it: ['Isaia e Isaia S.p.A.', 'Via Toledo 106 - 80134, Napoli, Italia', 'Via Roma 44 - 80013 Casalnuovo di Napoli (NA)', '5212370638'],
    ca: ['Isaia e Isaia Canada Ltd.', '1 Germain Street Suite 1500 Saint John NB E2L 4V1', 'Saint John, Canada', '751716671'],
    gb: ['Isaia UK Private Limited', '45 Conduit Street - London - United Kingdom', 'London, UK', '11258861'],
  };

  private readonly authRepository = inject(AuthRepository);
  private readonly state = {
    $stores: signal([] as GeographyStore[]),
  };

  public $stores = this.state.$stores.asReadonly();
  public $storesKeyed = computed(() => keyBy(this.$stores(), 'id'), { equal: isEqual });
  public $currentStore = computed(() => this.$storesKeyed()[this.authRepository.$storeId()!]);
  public $currentStoreName = computed(() => getGeographyStoreName(this.$currentStore()));
  public currentStorePriceListId = computed(() => this.$currentStore()?.priceList);

  public legalRawData = computed(() => {
    const countryCode = (this.$currentStore()?.countryCode || '').toLowerCase();
    return this.#LEGAL[countryCode] || [];
  });

  public legalReadableData = computed(() => this.legalRawData().join(' - '));

  public setStores(stores: GeographyStore[]) {
    this.state.$stores.set(stores);
  }

  public getStore(storeId: string) {
    return this.$storesKeyed()[storeId];
  }

  public getPriceListId(storeId: string) {
    return this.getStore(storeId)?.priceList;
  }
}
