// eslint-disable-next-line @nx/enforce-module-boundaries
import { IsaiaEntity, isIsaiaEntity, markIsaiaEntity } from '@isaia/entity';
import { OrderHistoryXhr } from './order-history.xhr';
import { OrderSource } from './order.source';
import { OrderItemStatus } from './order-item.status';

const ENTITY = 'ORDER_HISTORY';

export interface OrderHistory extends IsaiaEntity<typeof ENTITY> {
  id: string;
  totalInEuro: string;
  totalInCurrency: string;
  currency: string;
  storeId: string;
  customerId: string;
  salesAssociate: string;
  sentToProductionEmail?: boolean;
  items: Array<{
    aggregatorId?: string;
    itemId: string;
    categoryId: number;
    priceCategoryCode: string;
    salesAssociate: string;
    priceInEuro: string;
    priceInCurrency: string;
    currency: string;
    quantity: number;
    fabricId: string;
    fabricDescription: string;
    colorId: string;
    colorDescription: string;
    sku: string;
    transactionDate: string;
    status: OrderItemStatus;
  }>;
  source: OrderSource;
  transactionDate: string;
}

export function createOrderHistory(options: OrderHistoryXhr): OrderHistory {
  return markIsaiaEntity(ENTITY, {
    id: options.orderId,
    totalInEuro: options.totalInEuro,
    totalInCurrency: options.totalInCurrency,
    currency: options.currency,
    storeId: options.storeId,
    customerId: options.customerId,
    salesAssociate: options.salesAssociate,
    sentToProductionEmail: !!options.sentToProductionEmail,
    items:
      options.items?.map((item) => {
        return {
          aggregatorId: item.aggregatorId,
          itemId: item.itemId,
          categoryId: item.categoryId,
          priceCategoryCode: item.priceCategoryCode,
          salesAssociate: item.salesAssociate,
          priceInEuro: item.priceInEuro,
          priceInCurrency: item.priceInCurrency,
          currency: item.currency,
          quantity: item.quantity,
          fabricId: item.fabricId,
          fabricDescription: item.fabricDescription,
          colorId: item.colorId,
          colorDescription: item.colorDescription,
          sku: item.sku,
          transactionDate: item.transactionDate || '',
          status: item.status,
        };
      }) || [],
    source: options.source,
    transactionDate: options.transactionDate,
  });
}

export function isInstanceOfOrderHistory(value: unknown): value is OrderHistory {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
