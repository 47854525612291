import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject, Injector, runInInjectionContext } from '@angular/core';
import { CustomerService } from './customer.service';
import { injectPopupRouteResolverError } from '../router';
import { withErrorPopup } from '../http';
import { firstValueFrom } from 'rxjs';
import { ApplicationRepository } from '../application';
import { Customer } from '@isaia/entity/customer';

export function customerResolver(options?: { forceHttp?: boolean; useId?: string; injector?: Injector }) {
  return (activatedRouteSnapshot: ActivatedRouteSnapshot): Promise<Customer | undefined> => {
    const injector = options?.injector || inject(Injector);
    return runInInjectionContext(injector, () => {
      const router = inject(Router);
      const customerService = inject(CustomerService);
      const applicationRepository = inject(ApplicationRepository);
      const popupRouteResolverError = injectPopupRouteResolverError();

      if (!options?.forceHttp) {
        const customer = router.getCurrentNavigation()?.extras.state?.['customer'];
        if (customer) {
          return Promise.resolve(customer);
        }
      }

      const customerId = options?.useId || activatedRouteSnapshot.queryParams['customerId'] || activatedRouteSnapshot.params['customerId'];
      if (customerId) {
        const request$ = customerService.getCustomer(customerId, { context: withErrorPopup(false) });
        applicationRepository.setLoading(true);
        return firstValueFrom(request$)
          .catch((e) => {
            return popupRouteResolverError.open({ errorMessage: e.message, activatedRouteSnapshot });
          })
          .finally(() => {
            applicationRepository.setLoading(false);
          });
      }
      return Promise.resolve(undefined);
    });
  };
}
