// eslint-disable-next-line @nx/enforce-module-boundaries
import { IsaiaEntity, isIsaiaEntity, markIsaiaEntity } from '@isaia/entity';
import { OrderItemMediaXhr } from './order-item-media.xhr';

const ENTITY = 'ORDER_ITEM_PHOTO';

export interface OrderItemMedia extends IsaiaEntity<typeof ENTITY> {
  name: string;
  url: string;
}

export function createOrderItemMedia(options: OrderItemMediaXhr): OrderItemMedia {
  return markIsaiaEntity(ENTITY, {
    name: options.fileName,
    url: options.filePath,
  });
}

export function isInstanceOfOrderItemMedia(value: unknown): value is OrderItemMedia {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
