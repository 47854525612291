@for (error of $formattedErrors(); track $index) {
  <div class="form-field-error">
    <span [innerHTML]="error"></span>
  </div>
}

<div class="form-field-error__icon">
  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
    <path
      d="M479.789-298.77q11.942 0 20.23-8.078t8.288-20.019q0-11.941-8.078-20.229-8.077-8.288-20.018-8.288-11.942 0-20.23 8.077-8.288 8.078-8.288 20.019 0 11.941 8.078 20.23 8.077 8.288 20.018 8.288Zm.014-130.153q10.966 0 18.581-7.475 7.615-7.474 7.615-18.524v-188.002q0-11.049-7.418-18.524-7.418-7.475-18.384-7.475-10.966 0-18.581 7.475t-7.615 18.524v188.002q0 11.05 7.418 18.524 7.418 7.475 18.384 7.475Zm.54 312.922q-75.112 0-141.48-28.42-66.369-28.42-116.182-78.21-49.814-49.791-78.247-116.087t-28.433-141.673q0-75.378 28.42-141.246 28.42-65.869 78.21-115.682 49.791-49.814 116.087-78.247t141.673-28.433q75.378 0 141.246 28.42 65.869 28.42 115.682 78.21 49.814 49.791 78.247 115.853t28.433 141.173q0 75.112-28.42 141.48-28.42 66.369-78.21 116.182-49.791 49.814-115.853 78.247t-141.173 28.433ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
    />
  </svg>
</div>
