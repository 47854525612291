import { InternationalizationXhr } from './internationalization.xhr';

export interface Internationalization {
  slug: string;
  label: {
    [langISO6391: string]: string;
  };
}

export function createEntityInternalizationLabel<T extends InternationalizationXhr['label']>(label?: T): Internationalization['label'] {
  return label || { en: '' };
}
