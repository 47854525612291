import { Component, ViewEncapsulation, ChangeDetectionStrategy, signal } from '@angular/core';

@Component({
  selector: 'isa-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  public isRequiredSymbolVisible = signal(false);
}
