import { ApparelCategoryTaxonomy, createApparelCategoryTaxonomy, MEASUREMENT_TAXONOMY_SLUG } from '@isaia/entity/apparel';
import { clone } from '@isaia/clone';
import { Measurement, StaticMeasurement } from '@isaia/entity/measurement';
import { formatAsShortReadableString } from '@isaia/date';

export const NO_MEASUREMENT_VALUE = 'NO_MEASUREMENT';
export function isNoMeasurementValue(value?: string) {
  return value === NO_MEASUREMENT_VALUE;
}

export function formatMeasurementDate(m: Measurement, lang?: string) {
  return formatAsShortReadableString(m.updatedAt || m.createdAt, lang);
}

export function addAttributesToMeasurementTaxonomy(measurements: Measurement[], taxonomy: ApparelCategoryTaxonomy, lang?: string) {
  const validMeasurements = measurements.filter((m) => m.type === taxonomy.id);
  if (!validMeasurements.length) {
    return taxonomy;
  }
  const cloned = clone(taxonomy);
  cloned.attributes = validMeasurements.map((m) => {
    return { id: m.id, slug: m.data.name, label: { en: formatMeasurementDate(m, lang) } };
  });
  return cloned;
}

export function createMeasurementTaxonomy(entity: StaticMeasurement) {
  return createApparelCategoryTaxonomy({
    id: createMeasurementId(entity),
    slug: createMeasurementSlug(entity),
    label: entity.label,
    isTaxonomyMandatory: true,
    group: { slug: MEASUREMENT_TAXONOMY_SLUG, label: { en: '' } },
  });
}

function createMeasurementId(entity: StaticMeasurement) {
  return entity.category;
}

const MEASUREMENT_SEPARATOR = '|';
export function createMeasurementSlug(entityOrString: StaticMeasurement | string) {
  const slug = typeof entityOrString === 'string' ? entityOrString : entityOrString.category;
  return MEASUREMENT_TAXONOMY_SLUG + MEASUREMENT_SEPARATOR + slug;
}
export function getMeasurementTypeFromSlug(slug: string) {
  return slug.includes(MEASUREMENT_SEPARATOR) ? slug.split(MEASUREMENT_SEPARATOR)[1] : slug;
}

export function isMeasurementTaxonomy(entityOrString?: Partial<ApparelCategoryTaxonomy> | string) {
  if (typeof entityOrString === 'string') {
    return entityOrString.includes(MEASUREMENT_TAXONOMY_SLUG);
  }
  return [entityOrString?.group?.slug, entityOrString?.slug].includes(MEASUREMENT_TAXONOMY_SLUG);
}

export function getMeasurementIdFromSelectedTaxonomies(selectedTaxonomies?: Record<string, any>) {
  const data = selectedTaxonomies || {};
  return Object.keys(data).reduce<string[]>((acc, key) => {
    const value = data[key];
    if (isMeasurementTaxonomy(key) && value) {
      acc.push(value);
    }
    return acc;
  }, []);
}

export const MEASUREMENT_BODY_SLUG = 'body';
export function isBodyMeasurement(idOrSlug: string) {
  const id = idOrSlug.includes(MEASUREMENT_TAXONOMY_SLUG) ? getMeasurementTypeFromSlug(idOrSlug) : idOrSlug;
  return id === MEASUREMENT_BODY_SLUG;
}

export function hasAtLeastOneBodyMeasurement(measurements: Measurement[]) {
  return !!measurements.find((measurement) => {
    return isBodyMeasurement(measurement.type);
  });
}
