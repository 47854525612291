import { ApparelCategory, ApparelCategoryTaxonomy, ApparelCategoryTaxonomyAttribute } from '@isaia/entity/apparel';
import { StaticMeasurement, StaticMeasurementTaxonomy, StaticMeasurementTaxonomyType } from '@isaia/entity/measurement';
import { injectTranslateApi } from '@isaia/i18n/language';
import { ApparelRepository } from '../apparel/apparel.repository';
import { inject, Injector, runInInjectionContext } from '@angular/core';
import { InputAutocompleteOption } from '@isaia/components/input-autocomplete';

export const MEASUREMENT_TAXONOMY_NAME_SYMBOL = '-';

export function extractNamePrefixFromMeasurementTaxonomyOption<T extends { selected: any; options: InputAutocompleteOption[] }>(item: T) {
  const value = item?.selected;
  const label = item?.options?.find((o) => o.value === value)?.label;
  if (!label) {
    return '';
  }
  return label.includes(MEASUREMENT_TAXONOMY_NAME_SYMBOL) ? label.split(MEASUREMENT_TAXONOMY_NAME_SYMBOL)[1] : label;
}

export function injectMeasurementFacade(injector?: Injector) {
  const _injector = injector || inject(Injector);
  return runInInjectionContext(_injector, () => {
    const translateApi = injectTranslateApi();
    const apparelRepository = inject(ApparelRepository);

    function createTaxonomyOptionLabelFromAttribute(attr: ApparelCategoryTaxonomyAttribute) {
      return `${attr.id} ${MEASUREMENT_TAXONOMY_NAME_SYMBOL} ${translateApi(attr)()}`;
    }

    function createTaxonomyMeasurements(taxonomies: ApparelCategoryTaxonomy[]) {
      return taxonomies.map((t): StaticMeasurementTaxonomy => {
        return {
          type: StaticMeasurementTaxonomyType.Dropdown,
          id: t.slug,
          required: t.isTaxonomyMandatory,
          label: translateApi(t)(),
          isTaxonomy: true,
          options: t.attributes.map((attr) => {
            return { value: attr.id, id: attr.slug, label: createTaxonomyOptionLabelFromAttribute(attr) };
          }),
        };
      });
    }

    return {
      getFullTaxonomies(options: { model?: StaticMeasurement; category?: ApparelCategory }): StaticMeasurementTaxonomy[] {
        const measurement = options.model;
        if (!measurement) {
          return [] as StaticMeasurementTaxonomy[];
        }
        const taxonomies = apparelRepository.getTaxonomiesVisibleInMeasurement(options.category?.slug, measurement.category);
        const data = measurement.data || [];
        return taxonomies?.length ? [...createTaxonomyMeasurements(taxonomies), ...data] : data;
      },
    };
  });
}
