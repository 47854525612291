import { computed, inject, Injectable, isDevMode, signal } from '@angular/core';
import { LocalStorageService } from '@isaia/local-storage';
import { debugStore } from '../entity-store.debug';
import { isEqual } from 'lodash-es';
import { Session, SessionProduct } from '@isaia/entity/session';
import { RequireAtLeastOne } from 'type-fest';
import { Customer } from '@isaia/entity/customer';
import { createMoney, splitMoneyString } from '@qwentes/money';
import { ApparelRepository } from '../apparel';
import { PriceRepository } from '../price';
import { clone } from '@isaia/clone';
import { CustomerRepository } from '../customer';

type SessionState = Partial<{
  currentSession?: Session;
}>;

@Injectable()
export class SessionRepository {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly customerRepository = inject(CustomerRepository);
  private readonly priceRepository = inject(PriceRepository);
  private readonly apparelRepository = inject(ApparelRepository);
  private readonly $state = signal({} as SessionState);
  public readonly $currentSession = computed(() => this.$state()?.currentSession || ({} as Session), { equal: isEqual });
  public readonly $currentSessionId = computed(() => this.$currentSession().id);

  public readonly $customer = computed(() => {
    const session = this.$currentSession();
    return this.customerRepository.getCustomer(session.customerId);
  });

  public readonly $basketItems = computed(() => this.$currentSession().basket?.items || []);
  public readonly $basketTotal = computed(() => this.getTotalPrice(this.$basketItems()));
  public readonly $basketItemsQuantity = computed(() => {
    return this.$basketItems().reduce((acc, item) => (acc += item.quantity), 0);
  });

  constructor() {
    if (isDevMode()) {
      debugStore('session', this.$state);
    }

    this.localStorageService.syncValue(
      'session',
      () => this.$state(),
      (state) => this.$state.set(state || {}),
    );
  }

  public setCurrentSession(session?: RequireAtLeastOne<Session, 'id'>) {
    this.$state.update((state) => {
      const cloned = clone(state);
      cloned.currentSession = clone(session);
      return cloned;
    });
  }

  public isCurrentSessionId(sessionId?: Session['id']) {
    return this.$currentSessionId() === sessionId;
  }

  public hasCustomerId(customerId?: Customer['id']) {
    if (!customerId) {
      return false;
    }
    return this.$currentSession().customerId === customerId;
  }

  public releaseCurrentSession() {
    this.setCurrentSession(undefined);
  }

  public getSessionImage(session: Session) {
    const categoryId = session.basket?.items?.[0]?.categoryId;
    return this.apparelRepository.getCategoryImage(categoryId);
  }

  public getTotalPrice(items?: SessionProduct[]) {
    const [, shouldBeCurrency] = splitMoneyString(items?.[0]?.unitPrice || '');
    const currency = shouldBeCurrency || this.priceRepository.$currentCurrency();
    const initialMoney = createMoney({ amount: 0, currency });
    const initialMoneyRaw = initialMoney.formatToRawString();
    const total = items?.reduce((money, p) => {
      try {
        const itemUnitPrice = createMoney(p.unitPrice);
        const itemTotalPrice = itemUnitPrice.multiply(p.quantity);
        return money.sum(itemTotalPrice);
      } catch (e: Error | any) {
        alert(`SessionRepository.getTotalPrice: ${e?.message || 'error'}`);
        return initialMoney;
      }
    }, initialMoney);
    return {
      amount: total?.amount || initialMoney.amount,
      currency: total?.currency || initialMoney.currency,
      decimals: total?.decimals || initialMoney.decimals,
      symbol: total?.symbol || '',
      raw: total?.formatToRawString() || initialMoneyRaw,
      formatted: items?.length ? total?.formatIntl() : total?.formatIntl(),
    };
  }
}
