import { inject, Injectable, isDevMode, signal } from '@angular/core';
import { Customer } from '@isaia/entity/customer';
import { clone } from '@isaia/clone';
import { debugStore } from '../entity-store.debug';
import { LocalStorageService } from '@isaia/local-storage';

export interface CustomerState {
  customers: Partial<Record<string, Customer | null>>;
}

const INITIAL_STATE: CustomerState = { customers: {} };

@Injectable()
export class CustomerRepository {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly $store = signal<CustomerState>(INITIAL_STATE);

  constructor() {
    if (isDevMode()) {
      debugStore('customer', this.$store);
    }

    this.localStorageService.syncValue(
      'customer',
      () => this.$store(),
      (state) => this.$store.set(state || INITIAL_STATE),
    );
  }

  public addCustomer(customer?: Customer) {
    if (!customer) {
      return;
    }
    this.$store.update((state) => {
      const cloned = clone(state);
      cloned.customers[customer.id] = clone(customer);
      return cloned;
    });
  }

  public getCustomer(id?: string) {
    return id ? this.$store().customers[id] : undefined;
  }
}
