import { translate } from '@jsverse/transloco';
import { injectLanguage } from '@isaia/i18n/language';

export function fallbackMeasurementName(name?: string, lang?: string) {
  return name || translate('measurementsNoTitle', {}, lang);
}

export function injectFallbackMeasurementName() {
  const language = injectLanguage();
  return (name?: string, lang?: string) => {
    return fallbackMeasurementName(name, lang || language()) || '';
  };
}
