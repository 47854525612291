// eslint-disable-next-line @nx/enforce-module-boundaries
import { IsaiaEntity, isIsaiaEntity, markIsaiaEntity } from '@isaia/entity';
import { OrderItemXhr } from './order-item.xhr';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SessionProduct } from '@isaia/entity/session';
import { OrderItemStatus } from './order-item.status';

const ENTITY = 'ORDER_ITEM';

export interface OrderItem extends IsaiaEntity<typeof ENTITY> {
  id: string;
  aggregatorId: string;
  externalReferenceId?: string;
  salesAssociateId: string;
  storeId: string;
  itemData: SessionProduct & { totalPrice: string };
  status: OrderItemStatus;
  customerId: string;
  measurementIds: string[];
  createdAt: string;
  updatedAt?: string;
  model?: string;
}

export function createOrderItem(options: OrderItemXhr): OrderItem {
  return markIsaiaEntity(ENTITY, {
    id: options.id,
    aggregatorId: options.aggregatorId,
    externalReferenceId: options.externalReferenceId,
    salesAssociateId: options.salesAssociate,
    storeId: options.storeId,
    itemData: {
      ...options.itemData,
      unitPrice: options.unitPrice,
      totalPrice: options.totalPrice,
      categoryId: typeof options.categoryId === 'string' ? +options.categoryId : NaN,
      quantity: options.quantity!,
    },
    status: options.status,
    customerId: options.customerId,
    measurementIds: options.sartorialMeasurements?.map((id) => id) || [],
    createdAt: options.createdAt,
    updatedAt: options.updatedAt,
    model: options.itemModel,
  });
}

export function isInstanceOfOrderItem(value: unknown): value is OrderItem {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
