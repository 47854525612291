// eslint-disable-next-line @nx/enforce-module-boundaries
import { IsaiaEntity, isIsaiaEntity, markIsaiaEntity } from '@isaia/entity';
import { Customer } from '@isaia/entity/customer';
import { FabricSelection } from '@isaia/entity/fabric';
import { ApparelCategory } from '@isaia/entity/apparel';
import { SessionXhr } from './session.xhr';

const ENTITY = 'SESSION';

export interface Session extends IsaiaEntity<typeof ENTITY> {
  id: string;
  customerId?: Customer['id'];
  createdBy: SessionClientAdvisor;
  updatedBy: SessionClientAdvisor;
  createdAt: string;
  storeId: string;
  basket?: {
    items?: SessionProduct[];
  };
  lockedBy?: string | null;
}

export interface SessionProduct {
  id: string;
  fabric?: FabricSelection;
  categoryId: ApparelCategory['id'];
  deliveryDate?: string;
  quantity: number;
  unitPrice: string;
  frecciarossa: boolean;
  bespoke: boolean;
  isCustomPrice: boolean;
  externalReferenceId: string;
  suitVest: boolean;
  taxonomiesOrdered: string[];
  taxonomies: Partial<{ [taxonomyId: string]: string | number | boolean | string[] }>;
  salesAssociateFullName: string;
  storeName: string;
  tailor: string;
}

export interface SessionClientAdvisor {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
}

export function createSession(options: SessionXhr): Session {
  return markIsaiaEntity(ENTITY, {
    id: options.id,
    customerId: options.customerId,
    createdBy: {
      email: options.createdBy.email,
      username: options.createdBy.username,
      firstName: options.createdBy.firstName,
      lastName: options.createdBy.lastName,
    },
    updatedBy: {
      email: options.updatedBy?.email || '',
      username: options.updatedBy?.username || '',
      firstName: options.updatedBy?.firstName || '',
      lastName: options.updatedBy?.lastName || '',
    },
    createdAt: options.createdAt,
    storeId: options.storeId,
    lockedBy: options.lockedBy,
    basket: {
      items:
        options?.basket?.items?.map((i) => {
          return {
            id: i.id,
            fabric: {
              category: i.fabric?.category || '',
              code: i.fabric?.code || '',
              color: i.fabric?.color || '',
            },
            categoryId: i.categoryId,
            deliveryDate: i.deliveryDate,
            quantity: i.quantity,
            unitPrice: i.unitPrice,
            frecciarossa: !!i.frecciarossa,
            bespoke: !!i.bespoke,
            isCustomPrice: !!i.isCustomPrice,
            externalReferenceId: i.externalReferenceId,
            suitVest: i.suitVest,
            taxonomiesOrdered: i.taxonomiesOrdered || [],
            taxonomies: { ...i.taxonomies },
            salesAssociateFullName: i.salesAssociateFullName,
            storeName: i.storeName,
            tailor: i.tailor,
          };
        }) || [],
    },
  });
}

export function isInstanceOfSession(value: unknown): value is Session {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
