import { Component, ViewEncapsulation, ChangeDetectionStrategy, inject, computed, input } from '@angular/core';
import type { ValidationErrors } from '@angular/forms';
import { formFieldErrorFormatter } from './form-field-error-formatter';
import { LanguageService } from '@isaia/i18n/language';
import { toSignal } from '@angular/core/rxjs-interop';
import { EMPTY } from 'rxjs';

export type FormFieldErrorCustom = Readonly<{ [errorKey: string]: string | ((value: any) => string) }>;
type FormFieldErrors = ValidationErrors | ReadonlyMap<string, any> | undefined | null;

@Component({
  selector: 'isa-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent {
  public errors = input<FormFieldErrors | undefined>(undefined);
  public customErrors = input<FormFieldErrorCustom | undefined>(undefined);
  private readonly language = inject(LanguageService, { optional: true });
  private readonly $lang = toSignal(this.language?.lang$ || EMPTY);
  public readonly $formattedErrors = computed(() => {
    const [errors, customErrors] = [this.errors(), this.customErrors(), this.$lang()];
    if (!errors) {
      return [] as string[];
    }
    return Object.entries(errors).reduce<string[]>((acc, entry) => {
      const [key, config] = entry;
      const customFormatter = customErrors?.[key];
      const customFormatted = customFormatter ? (typeof customFormatter === 'function' ? customFormatter(config) : customFormatter) : '';
      const label = customFormatted || formFieldErrorFormatter[key]?.(config) || key;
      const value = this.language?.translate(label, config) || label;
      if (value) {
        acc.push(value);
      }
      return acc;
    }, []);
  });
}
