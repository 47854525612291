import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom, of } from 'rxjs';
import { inject, Injector } from '@angular/core';
import { ApplicationRepository } from '../application';
import { customerResolver } from '../customer';
import { OrderService } from './order.service';
import { OrderHistory, OrderItem } from '@isaia/entity/order';
import { Customer } from '@isaia/entity/customer';
import { injectPopupRouteResolverError } from '../router';
import { withErrorPopup } from '../http';

export type OrderHistoryResolverData = { order: OrderHistory; customer: Customer | undefined; mtmOrderItems: OrderItem[] };

export function orderHistoryResolver(activatedRouteSnapshot: ActivatedRouteSnapshot): Promise<OrderHistoryResolverData> {
  const router = inject(Router);
  const injector = inject(Injector);
  const orderService = inject(OrderService);
  const applicationRepository = inject(ApplicationRepository);
  const resolveCustomerId = (customerId: string) => customerResolver({ useId: customerId, injector })(activatedRouteSnapshot);
  const popupRouteResolverError = injectPopupRouteResolverError();

  const orderFromState = router.getCurrentNavigation()?.extras.state?.['orderHistory'] as OrderHistory | undefined;
  const orderId = (activatedRouteSnapshot.queryParams['orderId'] || activatedRouteSnapshot.params['orderId']) as string | undefined;
  const order$ = orderFromState ? of(orderFromState) : orderService.getOrderHistory(orderId!, { context: withErrorPopup(false) });
  const getMtmOrderItems = (aggregatorId?: string) => {
    return aggregatorId ? firstValueFrom(orderService.getOrderItemsByAggregatorId(aggregatorId)) : Promise.resolve([] as OrderItem[]);
  };

  applicationRepository.setLoading(true);
  return firstValueFrom(order$)
    .then((order) => {
      return Promise.all([getMtmOrderItems(order.items?.[0]?.aggregatorId), resolveCustomerId(order.customerId)]).then(
        ([mtmOrderItems, customer]) => {
          return { order, customer, mtmOrderItems };
        },
      );
    })
    .catch((e) => {
      popupRouteResolverError.open({ errorMessage: `Order <b>${orderId}</b> not found`, activatedRouteSnapshot });
      throw e;
    })
    .finally(() => applicationRepository.setLoading(false));
}
