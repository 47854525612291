import { createMoney } from '@qwentes/money';
import { PRICE_RANGE_PREFIX } from '@isaia/entity/price';

export function createPriceFormatted<T extends { totalInEuro?: string; totalInCurrency?: string }>(data: T) {
  const priceInEuro = data.totalInEuro || data.totalInCurrency;
  return priceInEuro ? createMoney(priceInEuro).formatIntl() : '';
}

export const removeRangePrefix = (range: string) => {
  return range.startsWith(PRICE_RANGE_PREFIX) ? range.substring(3) : range;
};
