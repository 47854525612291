import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'lodash-es';

@Pipe({
  name: 'orderSalesAssociateName',
})
export class OrderSalesAssociateNamePipe implements PipeTransform {
  public transform(value: string): string {
    try {
      return value.split('@')[0].split('.').map(capitalize).join(' ');
    } catch (e) {
      return value;
    }
  }
}
