import { Customer } from '@isaia/entity/customer';
import { Pipe, PipeTransform } from '@angular/core';

export function getCustomerFullName<T extends { firstName?: string; lastName?: string }>(customer?: T | null | void) {
  return `${customer?.firstName || ''} ${customer?.lastName || ''}`.trim();
}

@Pipe({
  standalone: true,
  name: 'isaCustomerFullName',
})
export class CustomerFullNamePipe implements PipeTransform {
  public transform(customer?: Partial<Customer> | null | void) {
    return getCustomerFullName(customer);
  }
}
