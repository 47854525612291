import { inject, Injectable } from '@angular/core';
import {
  HttpCreateMeasurementData,
  HttpGetMeasurementsOptions,
  HttpUpdateMeasurementData,
  MeasurementApiService,
} from '@isaia/measurement';
import { HttpGetOptions, HttpPostOptions } from '@isaia/entity/http';
import { Measurement } from '@isaia/entity/measurement';
import { MeasurementRepository } from './measurement.repository';
import { injectMeasurementDialog } from './measurement.dialog';
import { ApplicationRepository } from '../application';
import { firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { GetOrderItemsParams } from '@isaia/order';
import { OrderItem, OrderItemStatus } from '@isaia/entity/order';
import { OrderService } from '../order';

@Injectable()
export class MeasurementService {
  private readonly measurementApiService = inject(MeasurementApiService);
  private readonly measurementRepository = inject(MeasurementRepository);
  private readonly applicationRepository = inject(ApplicationRepository);
  private readonly measurementDialog = injectMeasurementDialog();
  private readonly orderService = inject(OrderService);

  public getStaticMeasurements(options?: HttpGetOptions) {
    return this.measurementApiService
      .getStaticMeasurements(options)
      .pipe(tap((measurements) => this.measurementRepository.setStaticMeasurements(measurements)));
  }

  public getMeasurements(options?: HttpGetMeasurementsOptions) {
    return this.measurementApiService.getMeasurements(options);
  }

  public createMeasurement(data: HttpCreateMeasurementData, options?: HttpPostOptions) {
    return this.measurementApiService.createMeasurement(data, options);
  }

  public updateMeasurement(data: HttpUpdateMeasurementData, options?: HttpPostOptions) {
    return this.measurementApiService.updateMeasurement(data, options);
  }

  public canEditMeasurement(measurement?: Measurement): Promise<boolean | undefined> {
    if (!measurement) {
      return Promise.resolve(false);
    }
    this.applicationRepository.setLoading(true);
    const orders$ = this.orderService
      .getOrderItems({
        params: {
          [GetOrderItemsParams.FilterMeasurement]: measurement.id,
          [GetOrderItemsParams.FilterStatus]: [
            OrderItemStatus.Pending,
            OrderItemStatus.ReadyForProduction,
            OrderItemStatus.SendToProduction,
          ].join(','),
        },
      })
      .pipe(
        map((res) => {
          return res.orderItems.reduce<Partial<Record<OrderItemStatus, OrderItem[]>>>((acc, orderItem) => {
            if (!acc[orderItem.status]) {
              acc[orderItem.status] = [];
            }
            acc[orderItem.status]?.push(orderItem);
            return acc;
          }, {});
        }),
        tap(() => this.applicationRepository.setLoading(false)),
      );

    const canEdit$ = orders$.pipe(
      switchMap((orderItems): Observable<boolean | undefined> => {
        const sendToProduction = orderItems[OrderItemStatus.SendToProduction]?.length || 0;
        if (sendToProduction > 0) {
          return this.measurementDialog.openMeasurementInOrderError(sendToProduction);
        }
        const pending = orderItems[OrderItemStatus.Pending]?.length || 0;
        const readyForProduction = orderItems[OrderItemStatus.ReadyForProduction]?.length || 0;
        const total = pending + readyForProduction;
        if (total > 0) {
          return this.measurementDialog.openMeasurementInOrderWarning(total);
        }
        return of(true);
      }),
    );

    return firstValueFrom(canEdit$);
  }
}
