import { inject } from '@angular/core';
import { SessionService } from './session.service';
import { SetRequired } from 'type-fest';
import { Customer } from '@isaia/entity/customer';
import { SessionProduct } from '@isaia/entity/session';
import { OrderItem } from '@isaia/entity/order';
import { RouterResolverService } from '../router';

interface GoToCompositionCategorySummaryOptions {
  customer?: Customer;
  sessionProduct?: SessionProduct;
  categoryId?: string;
  orderItem?: OrderItem;
}

type GoToOptions = SetRequired<GoToCompositionCategorySummaryOptions, 'customer'>;

export function injectSessionFacade() {
  const routerResolverService = inject(RouterResolverService);
  const sessionService = inject(SessionService);
  return {
    addCustomerToSessionAndGoToNextItem: (options: GoToOptions) => {
      return sessionService.addCustomerToSession(options.customer, { showConfirmationDialog: false }).then(() => {
        const { categoryId, sessionProduct, customer, orderItem } = options || {};
        const ensureCategoryId = categoryId || sessionProduct?.categoryId?.toString() || orderItem?.itemData.categoryId.toString();
        if (ensureCategoryId) {
          routerResolverService.order.composition.category
            .summary({ params: { categoryId: ensureCategoryId }, queryParams: { orderItemId: orderItem?.id } })
            .navigate({ state: { sessionProduct, customer, orderItem } });
        } else if (customer) {
          routerResolverService.customer.item.create({ params: { customerId: customer.id } }).navigate({ state: { customer } });
        } else {
          routerResolverService.order.overview.navigate();
        }
      });
    },
  };
}
