import { inject } from '@angular/core';
import { DialogService } from '@isaia/components/dialog';
import { firstValueFrom } from 'rxjs';
import { RouterResolverService } from './router.resolver';
import { ActivatedRouteSnapshot } from '@angular/router';

export function injectPopupRouteResolverError() {
  const routerResolverService = inject(RouterResolverService);
  const dialogService = inject(DialogService);
  return {
    open(options: { errorMessage: string; activatedRouteSnapshot?: ActivatedRouteSnapshot; redirectTo?: boolean | (() => void) }) {
      const resolvingRoute = options.activatedRouteSnapshot?.routeConfig?.path || '';
      const resolvingRouteMessage = resolvingRoute ? `\n\nResolving route: ${resolvingRoute}` : '';
      const hasRedirect = options.redirectTo !== false;
      const redirectMessage = hasRedirect ? `\nYou will redirect to Home` : '';
      const popup$ = dialogService
        .openPopup(
          {
            title: 'Error',
            message: options.errorMessage + resolvingRouteMessage + redirectMessage,
          },
          { disableClose: false },
        )
        .afterOpened();
      return firstValueFrom(popup$).then(() => {
        const redirect = options.redirectTo;
        if (typeof redirect === 'function') {
          redirect();
        } else if (typeof redirect === 'boolean' && !redirect) {
          // don't do redirects
        } else {
          routerResolverService.order.navigate();
        }
        return Promise.reject();
      });
    },
  };
}
