import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { HttpGetOptions, HttpPaginationParams, HttpResponse, HttpResponseWithPagination } from '@isaia/entity/http';
import { PRICE_API_ORIGIN } from './price-api-origin.token';
import { createPrice, PriceKeyed, PriceXhr } from '@isaia/entity/price';

type AvailableFilters = 'priceListId' | 'categoryPriceCode' | 'pricingGroup' | 'modelGroup' | 'season';
type QueryParams = Partial<Record<`filter[${AvailableFilters}]`, string>>;
export type HttpGetPricesOptions = HttpGetOptions<HttpPaginationParams & QueryParams>;

const DEFAULT_PAGINATION_PARAMS: HttpPaginationParams = { 'page[limit]': 20000, 'page[offset]': 0 };

@Injectable()
export class PriceApiService {
  private readonly http = inject(HttpClient);
  private readonly API_ORIGIN = inject(PRICE_API_ORIGIN);

  public getAllPriceList(options?: HttpGetPricesOptions) {
    const url = `${this.API_ORIGIN}/prices`;
    return this.http
      .get<HttpResponseWithPagination<PriceXhr[]>>(url, {
        ...options,
        params: { ...DEFAULT_PAGINATION_PARAMS, ...options?.params },
      })
      .pipe(
        map((response) => {
          return response.data.reduce<PriceKeyed>((acc, priceXhr) => {
            if (!acc[priceXhr.priceListId]) {
              acc[priceXhr.priceListId] = [];
            }
            acc[priceXhr.priceListId].push(createPrice(priceXhr as unknown as PriceXhr));
            return acc;
          }, {});
        }),
      );
  }

  public getPriceList(priceListId: string, options?: HttpGetPricesOptions) {
    const url = `${this.API_ORIGIN}/${priceListId}/prices`;
    return this.http
      .get<HttpResponse<PriceXhr[]>>(url, {
        ...options,
        params: { ...DEFAULT_PAGINATION_PARAMS, ...options?.params, 'filter[priceListId]': priceListId },
      })
      .pipe(
        map((response) => {
          return response.data.reduce<PriceKeyed>((acc, priceXhr) => {
            if (!acc[priceXhr.priceListId]) {
              acc[priceXhr.priceListId] = [];
            }
            acc[priceXhr.priceListId].push(createPrice(priceXhr as unknown as PriceXhr));
            return acc;
          }, {});
        }),
      );
  }
}
