export function formatAsLongReadableString(date: string | Date, lang = 'en') {
  // Thu, December 21
  return new Intl.DateTimeFormat([lang], {
    month: 'long',
    day: 'numeric',
    weekday: 'short',
  }).format(typeof date === 'string' ? new Date(date) : date);
}

export function formatAsShortReadableString(date: string | Date, lang = 'en') {
  // Dec 6, 2023
  return new Intl.DateTimeFormat([lang], {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(typeof date === 'string' ? new Date(date) : date);
}

const TIME_OF_DAY = 'T';
export function normalizeDateString<T = unknown>(date?: T) {
  /*
   * in Safari dates like '2023-10-10 18:58:16' are invalid.
   * All dates must include 'T', valid normalized date -> 2023-10-10T18:58:16
   */
  if (!date || typeof date !== 'string') {
    return date;
  }

  if (date.includes(TIME_OF_DAY)) {
    return date;
  }

  const [calendar, time] = date.split(' ');
  return [calendar, time].filter((v) => !!v).join(TIME_OF_DAY);
}
