import { inject, Injectable } from '@angular/core';
import { ApparelRepository } from './apparel.repository';
import { ApparelApiService, HttpGetCategoriesOptions } from '@isaia/apparel';
import { catchError, tap, throwError } from 'rxjs';

@Injectable()
export class ApparelService {
  private readonly apparelApiService = inject(ApparelApiService);
  private readonly apparelRepository = inject(ApparelRepository);

  public getCategories(options?: HttpGetCategoriesOptions) {
    return this.apparelApiService.getCategories(options).pipe(
      tap((value) => {
        this.apparelRepository.setCategories(value);
        return value;
      }),
      catchError((e) => {
        this.apparelRepository.setCategories([]);
        return throwError(() => e);
      }),
    );
  }
}
