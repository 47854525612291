import { signal } from '@angular/core';
import { Measurement } from '@isaia/entity/measurement';
import { clone } from '@isaia/clone';

export const createMeasurementSavedRepository = () => {
  const store = signal([] as Measurement[]);
  return {
    data: store.asReadonly(),
    set(measurements: Measurement[]) {
      store.set(measurements);
    },
    add(measurement: Measurement) {
      store.update((measurements) => [...measurements, measurement]);
    },
    update(measurement: Measurement) {
      store.update((all) => all.map((m) => (m.id === measurement.id ? clone(measurement) : m)));
    },
    find(id?: string) {
      if (!id) {
        return undefined;
      }
      return store().find((m) => m.id === id);
    },
  };
};
