import { createMoney, Money } from '@qwentes/money';
import { ExtraChargeStrategy, getExtraCharge } from './price-calculator.extra-charge';

type FrecciarossaValue = boolean;
export type FrecciarossaHistory = [FrecciarossaValue, FrecciarossaValue];
type FrecciarossaConfig = { add: boolean; remove: boolean; isActive: boolean };

export function normalizeFrecciarossaAction(actives?: FrecciarossaHistory): FrecciarossaConfig {
  const [prev, current] = actives || [false, false];
  return { add: !prev && current, remove: !current && prev, isActive: current };
}

export const createFrecciarossaCalculator: ExtraChargeStrategy = (currency, byCategorySlug) => {
  const frecciarossa = getExtraCharge(currency, byCategorySlug);
  return {
    add(price: Money) {
      return price.sum(frecciarossa);
    },
    remove(price: Money) {
      const nextPrice = price.sub(frecciarossa);
      return nextPrice.amount >= 0 ? nextPrice : createMoney({ amount: 0, currency });
    },
  };
};
