import { inject } from '@angular/core';
import { createPopupButton, DialogService } from '@isaia/components/dialog';
import { translate } from '@jsverse/transloco';
import { map, Observable } from 'rxjs';

export function injectMeasurementDialog() {
  const dialogService = inject(DialogService);

  return {
    openMeasurementInOrderError(quantity: number): Observable<boolean | undefined> {
      return dialogService
        .openPopup({
          title: translate('customerMeasurementErrorMeasurementInOrderPopupTitle'),
          message: translate('customerMeasurementErrorMeasurementInOrderPopupMessage', { quantity }),
          buttons: [
            createPopupButton({
              label: translate('customerMeasurementErrorMeasurementInOrderPopupButtonReject'),
            }),
          ],
        })
        .afterClosed()
        .pipe(map(() => false));
    },

    openMeasurementInOrderWarning(quantity: number): Observable<boolean | undefined> {
      return dialogService
        .openPopup({
          title: translate('customerMeasurementWarningMeasurementInOrderPopupTitle'),
          message: translate('customerMeasurementWarningMeasurementInOrderPopupMessage', { quantity }),
          buttons: [
            createPopupButton({
              label: translate('customerMeasurementWarningMeasurementInOrderPopupButtonReject'),
              onTap: { confirm: false },
            }),
            createPopupButton({
              type: 'flat',
              color: 'primary',
              label: translate('customerMeasurementWarningMeasurementInOrderPopupButtonConfirm'),
              onTap: { confirm: true },
            }),
          ],
        })
        .afterClosed()
        .pipe(map((res) => res?.action?.confirm));
    },
  };
}
