import { effect, isSignal, Signal } from '@angular/core';

function getStoreValue(store: Record<string, Signal<any>> | Signal<any>) {
  if (isSignal(store)) {
    return store();
  }
  return Object.keys(store).reduce((acc, k) => {
    return { ...acc, [k]: (store as any)[k]?.() };
  }, {});
}

function clone(value: unknown) {
  return JSON.parse(JSON.stringify(value));
}

export function debugStore(name: string, store: any) {
  // effect(() => {
  //   const clonedStore = clone(getStoreValue(store));
  //   (window as any).__ISAIA_STORE__ = (window as any).__ISAIA_STORE__ || {};
  //   (window as any).__ISAIA_STORE__[name] = clonedStore;
  //   console.log(clone((window as any).__ISAIA_STORE__));
  // });
}
