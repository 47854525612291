import { inject, Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { OrderService } from './order.service';
import { injectOrderDialog } from './order.dialog';
import { getCustomerFullName } from '@isaia/customer';
import { catchError, filter, finalize, firstValueFrom, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { HttpSendToProductionData } from '@isaia/order';
import { Customer } from '@isaia/entity/customer';
import { AuthRepository } from '../auth';
import { GeographyRepository } from '../geography';
import { ApplicationRepository } from '../application';
import { MadeToMeasureRouterReuseStrategy } from '../router';
import { withErrorPopup } from '../http';

/*
 * Safari/iOS hack:
 * window.open doesn't work inside an async function flow.
 * we're creating an helper function that provide a new tab and then attach the new url.
 */
function createNewWindowTab() {
  const newWindowTab = window.open('about:blank', '_blank');
  return {
    open(url: string) {
      if (newWindowTab) {
        newWindowTab.location = url;
      }
    },
    close() {
      if (newWindowTab) {
        newWindowTab.close();
      }
    },
  };
}

export type CreateProductionPayloadAction<T> = (payload: HttpSendToProductionData) => Observable<T>;

interface CreateProductionPayloadOptions {
  orderId: string;
  customer?: Customer;
}

@Injectable()
export class OrderActionProductionService {
  private readonly routeReuseStrategy = inject(RouteReuseStrategy) as MadeToMeasureRouterReuseStrategy;
  private readonly orderService = inject(OrderService);
  private readonly orderDialog = injectOrderDialog();
  private readonly authRepository = inject(AuthRepository);
  private readonly geographyRepository = inject(GeographyRepository);
  private readonly applicationRepository = inject(ApplicationRepository);

  private createProductionPayload(options: CreateProductionPayloadOptions) {
    const { orderId, customer } = options;
    return this.orderDialog
      .openChooseSendToProductionEmailContent({
        orderId,
        storeName: this.geographyRepository.$currentStoreName(),
        customerFullName: getCustomerFullName(customer),
        salesAssociateFullName: this.authRepository.fullName(),
      })
      .pipe(
        map((event): HttpSendToProductionData | undefined => {
          const email = event?.email;
          if (!email) {
            return undefined;
          }
          return {
            legal: this.geographyRepository.legalRawData(),
            storeName: this.geographyRepository.$currentStoreName(),
            orderId,
            subject: email.subject,
            body: email.body,
            to: this.authRepository.sentToProductionEmails(),
            cc: [this.authRepository.$email()],
          };
        }),
      );
  }

  public shouldPerformProductionRequest<T>(options: CreateProductionPayloadOptions & { action: CreateProductionPayloadAction<T> }) {
    const { orderId, customer, action } = options;
    return this.createProductionPayload({ orderId, customer }).pipe(
      filter((v): v is HttpSendToProductionData => !!v),
      switchMap((payload) => {
        this.applicationRepository.setLoading(true);
        return action(payload).pipe(
          catchError((e) => {
            if (e?.status === HttpStatusCode.ServiceUnavailable) {
              this.orderDialog.openSmtpError();
              // don't throw error, so we can fetch again items to get the new order-item status
              return of(null);
            }
            return throwError(e);
          }),
          switchMap(() => this.orderService.getOrderHistory(orderId)),
          tap(() => this.routeReuseStrategy.resetCacheOf('mtm-history-mtm-list')),
          finalize(() => this.applicationRepository.setLoading(false)),
        );
      }),
    );
  }

  public openPdf(orderId: string) {
    const pdf$ = this.orderService.getOrderPdf(orderId, {
      context: withErrorPopup({
        title: `Order #${orderId}`,
        message: (e) => e?.error?.error || 'PDF Unknown Error',
      }),
    });
    this.applicationRepository.setLoading(true);
    const newWindowTab = createNewWindowTab();
    firstValueFrom(pdf$)
      .then((res) => {
        newWindowTab.open(res.url);
      })
      .catch((e) => {
        newWindowTab.close();
      })
      .finally(() => {
        this.applicationRepository.setLoading(false);
      });
  }
}
