// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { CustomerXhr } from './customer.xhr';
import { CustomerSource } from './customer.source';

const ENTITY = 'CUSTOMER';

export interface Customer extends IsaiaEntity<typeof ENTITY> {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  phone: string;
  lastName: string;
  firstName: string;
  externalId: string;
  lastPurchaseDate?: string;
  primaryStore?: string;
  primarySalesAssociate?: string;
  source: CustomerSource;
}

export function createCustomer(options: Partial<CustomerXhr>): Customer {
  return markIsaiaEntity(ENTITY, {
    id: options.id,
    createdAt: options.createdAt,
    updatedAt: options.updatedAt,
    email: options?.email,
    phone: options?.phone,
    lastName: options?.lastName,
    firstName: options?.firstName,
    externalId: options.externalId,
    lastPurchaseDate: options?.lastPurchaseDate || '',
    primaryStore: options?.primaryStore,
    primarySalesAssociate: options?.primarySalesAssociate,
    source: options.source,
  });
}

export function isInstanceOfCustomer(value: unknown): value is Customer {
  return isIsaiaEntity(value, ENTITY);
}
