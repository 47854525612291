// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { MeasurementXhr } from './measurement.xhr';
import { ApparelCategory } from '@isaia/entity/apparel';
import { Customer } from '@isaia/entity/customer';
import { MeasurementStatus } from './measurement.status';

const ENTITY = 'MEASUREMENT';

export interface Measurement extends IsaiaEntity<typeof ENTITY> {
  id: string;
  status: MeasurementStatus;
  type: string;
  data: MeasurementXhr['data'];
  customerId: Customer['id'];
  categoryId: ApparelCategory['id'];
  createdAt: string;
  updatedAt: string;
  orderItemId?: string;
}

export function createMeasurement(options: Partial<MeasurementXhr>): Measurement {
  return markIsaiaEntity(ENTITY, {
    id: options.id,
    status: options.status,
    type: options.type,
    data: options.data || { name: '', section: {} },
    customerId: options.customerId,
    categoryId: options.categoryId,
    createdAt: options.createdAt || '',
    updatedAt: options.updatedAt || '',
    orderItemId: options.orderItemId,
  });
}

export function isInstanceOfMeasurement(value: unknown): value is Measurement {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
