import { createMoney } from '@qwentes/money';
import { round } from 'lodash-es';
import { createFrecciarossaCalculator, normalizeFrecciarossaAction } from './price-calculator.frecciarossa';
import type { CalculatePriceOptions, CalculatePriceStrategy } from './price-calculator.interface';
import { ApparelCategoryPricingRole } from '@isaia/entity/apparel';
import { getPriceByPriceRange } from './price-calculator.price-range.strategy';
import { getPriceByStyleMaterial } from './price-calculator.style-material.strategy';
import { createBespokeCalculator } from './price-calculator.bespoke';

export function calculatePrice(options: CalculatePriceOptions) {
  const { currency, decimals, ...price } = calculator(options);
  const amount = round(price.amount, decimals);
  return createMoney({ amount, currency, decimals });
}

function calculator(options: CalculatePriceOptions) {
  const currency = options.currency;
  const pricingRole = options.category.pricingRole;

  const strategy = STRATEGY[pricingRole]?.(options);
  if (!strategy || strategy.reset()) {
    return createMoney({ amount: 0, currency });
  }

  const calculated = strategy.calculate();
  const dynamicPrice = createMoney(calculated?.price || `0|${currency}`);
  const frecciarossaConfig = normalizeFrecciarossaAction(options.frecciarossa);

  const maybeWithFrecciarossaPrice = frecciarossaConfig.isActive
    ? createFrecciarossaCalculator(currency, options.category.slug).add(dynamicPrice)
    : dynamicPrice;

  return options.bespoke
    ? createBespokeCalculator(currency, options.category.slug).add(maybeWithFrecciarossaPrice)
    : maybeWithFrecciarossaPrice;
}

const STRATEGY: Record<ApparelCategoryPricingRole, CalculatePriceStrategy | undefined> = {
  [ApparelCategoryPricingRole.PriceRange]: getPriceByPriceRange,
  [ApparelCategoryPricingRole.StyleMaterial]: getPriceByStyleMaterial,
};
