import { Pipe, PipeTransform } from '@angular/core';
import { StaticMeasurement } from '@isaia/entity/measurement';
import { injectLanguage } from '@isaia/i18n/language';

@Pipe({
  standalone: true,
  name: 'isaMeasurementName',
})
export class MeasurementNamePipe implements PipeTransform {
  private readonly lang = injectLanguage();

  public transform(measurement?: StaticMeasurement) {
    return this.getTranslatedName(measurement);
  }

  private getTranslatedName(measurement?: StaticMeasurement) {
    if (!measurement) {
      return '';
    }
    const _lang = this.lang() || 'en';
    return measurement.label[_lang] || '';
  }
}
