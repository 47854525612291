export const MEASUREMENT_TAXONOMY_SLUG = 'measurements';
export const NOTE_TAXONOMY_SLUG = 'notes';
export const PHOTO_TAXONOMY_SLUG = 'photos';
export const EC4_LABEL_DESC_TAXONOMY_SLUG = 'ec4labeldesc';
export const EC4_TEXT_CASE_TAXONOMY_SLUG = 'ec4textcase';
export const MONOGRAM_TEXT_TAXONOMY_SLUG = 'monogramtext';
export const SKU_TAXONOMY_SLUG = 'sku';
export const INITIAL_EMBROIDERY_TAXONOMY_SLUG = 'initials_embroidery';

export const STATIC_TAXONOMIES = [NOTE_TAXONOMY_SLUG, PHOTO_TAXONOMY_SLUG];
export const FREE_TEXT_TAXONOMIES = [
  NOTE_TAXONOMY_SLUG,
  EC4_LABEL_DESC_TAXONOMY_SLUG,
  MONOGRAM_TEXT_TAXONOMY_SLUG,
  INITIAL_EMBROIDERY_TAXONOMY_SLUG,
];

export function isFreeTextTaxonomy(slug?: string) {
  return slug ? FREE_TEXT_TAXONOMIES.includes(slug) : false;
}

export function isSkuTaxonomy(slug?: string) {
  return slug === SKU_TAXONOMY_SLUG;
}
