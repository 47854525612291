import { Pipe, PipeTransform } from '@angular/core';
import { GeographyStore } from '@isaia/entity/geography';

export function getGeographyStoreName(store?: Partial<GeographyStore>) {
  return `${store?.name || ''}`.trim();
}

@Pipe({
  standalone: true,
  name: 'isaGeographyStoreName',
})
export class GeographyStoreNamePipe implements PipeTransform {
  public transform(store?: Parameters<typeof getGeographyStoreName>[0]) {
    return getGeographyStoreName(store);
  }
}
