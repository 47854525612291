import { CalculatePriceStrategy } from './price-calculator.interface';

export const getPriceByPriceRange: CalculatePriceStrategy = (options) => {
  return {
    calculate() {
      const taxonomyRequiredToCalculatePrice = options.taxonomyRequiredToCalculatePrice || [];
      const taxonomyValue = options.taxonomyValue || {};
      const prices = options.prices;
      const categoryCodes = options.category.priceCategoryCodes;
      const categoryModelGroups = options.category.modelGroups;
      const priceGroup = `000${options.fabric?.range || ''}`;
      const hasRequiredTaxonomies = taxonomyRequiredToCalculatePrice.length;
      return prices?.find((price) => {
        const hasSameCategoryCode = categoryCodes.includes(price.priceCategoryCode);
        const hasSameGroup = price.group === priceGroup;
        if (hasSameCategoryCode && hasSameGroup) {
          if (hasRequiredTaxonomies) {
            const taxonomiesSelected = Object.values(taxonomyValue);
            const foundModelGroup = categoryModelGroups.find((modelGroup) => {
              return taxonomiesSelected.includes(modelGroup.model);
            });
            return foundModelGroup?.group === price.modelGroup;
          }
          return categoryModelGroups[0].group === price.modelGroup;
        }
        return false;
      });
    },

    reset() {
      const hasSomeEmptyValue = !!options.taxonomyRequiredToCalculatePrice?.some((t) => !options.taxonomyValue?.[t.slug]);
      if (hasSomeEmptyValue) {
        return true;
      }
      // we are checking all fabric sections and not if fabric exists because we can have custom fabric codes that are not a real fabric
      const hasAllFabricInfo = Object.values(options.fabricSelection || {}).every((v) => !!v);
      return !hasAllFabricInfo;
    },
  };
};
