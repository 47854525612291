import { ApparelCategorySlug, ApparelCategoryTaxonomy } from '@isaia/entity/apparel';
import { SessionProduct } from '@isaia/entity/session';

const TAXONOMY_SLUG_GENERIC_COMPUTED_MODEL = ['style', 'jacket_front', 'pocket', 'garment', 'lining_type', 'manufacture'];
const TAXONOMY_SLUG_SHIRT_COMPUTED_MODEL = ['body', 'collar_style', 'back', 'front', 'cuff', 'classification'];

const APPAREL_CATEGORY_SLUG_WITH_COMPUTED_CODE_FROM_TAXONOMIES: Partial<Record<ApparelCategorySlug, string[]>> = {
  [ApparelCategorySlug.Suit]: TAXONOMY_SLUG_GENERIC_COMPUTED_MODEL,
  [ApparelCategorySlug.Jacket]: TAXONOMY_SLUG_GENERIC_COMPUTED_MODEL,
  [ApparelCategorySlug.Tuxedo]: TAXONOMY_SLUG_GENERIC_COMPUTED_MODEL,
  [ApparelCategorySlug.TuxedoJacket]: TAXONOMY_SLUG_GENERIC_COMPUTED_MODEL,
  [ApparelCategorySlug.Shirt]: TAXONOMY_SLUG_SHIRT_COMPUTED_MODEL,
  [ApparelCategorySlug.TuxedoShirt]: TAXONOMY_SLUG_SHIRT_COMPUTED_MODEL,
};

const APPAREL_CATEGORY_SLUG_WITH_COMPUTED_CODE = Object.keys(
  APPAREL_CATEGORY_SLUG_WITH_COMPUTED_CODE_FROM_TAXONOMIES,
) as ApparelCategorySlug[];

export function isCategoryWithComputedCode(slug?: string | ApparelCategorySlug) {
  return APPAREL_CATEGORY_SLUG_WITH_COMPUTED_CODE.includes(slug as ApparelCategorySlug);
}

export function isTaxonomyForComputedModel(categorySlug?: ApparelCategorySlug, taxonomySlug?: string) {
  return categorySlug && taxonomySlug
    ? APPAREL_CATEGORY_SLUG_WITH_COMPUTED_CODE_FROM_TAXONOMIES?.[categorySlug]?.includes(taxonomySlug) || false
    : false;
}

export function getComputedModelId(
  categorySlug?: ApparelCategorySlug,
  taxonomies?: ApparelCategoryTaxonomy[],
  activeTaxonomies?: Partial<SessionProduct['taxonomies']>,
) {
  if (!categorySlug) {
    return '';
  }
  if (!taxonomies?.length) {
    return '';
  }
  return taxonomies
    .filter((t) => isTaxonomyForComputedModel(categorySlug, t.slug))
    .map((t) => activeTaxonomies?.[t.slug] || '_')
    .join('');
}
