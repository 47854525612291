import { computed, inject, Injectable, signal } from '@angular/core';
import { Price, PriceKeyed } from '@isaia/entity/price';
import { GeographyRepository } from '../geography';
import { removeRangePrefix } from './price.helper';

@Injectable()
export class PriceRepository {
  public readonly geographyRepository = inject(GeographyRepository);
  private readonly state = {
    $allPriceList: signal<PriceKeyed | undefined>(undefined),
  };

  public $allPriceList = this.state.$allPriceList.asReadonly();
  public $currentPrices = computed(() => this.getPriceList(this.geographyRepository.currentStorePriceListId()));
  public $currentCurrency = computed(() => this.getCurrency(this.$currentPrices()));

  public allPriceRanges = computed(() => {
    if (!this.$allPriceList()) {
      return [];
    }
    const ranges = new Set(
      Object.values(this.$allPriceList() || {}).reduce(
        (acc, curr) => [...acc, ...curr.map((p) => removeRangePrefix(p.group))],
        [] as string[],
      ),
    );
    return Array.from(ranges);
  });

  public getCurrency(priceList?: Price[]) {
    return priceList?.[0]?.currency || '';
  }

  public getPriceList(id?: string | null) {
    const allPrices = this.$allPriceList();
    return allPrices?.[id || ''] || [];
  }

  public setAllPriceList(prices?: PriceKeyed) {
    this.state.$allPriceList.set(prices);
  }
}
