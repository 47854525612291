import { Internationalization } from '@isaia/entity/internationalization';
import { ApparelCategorySlug } from '@isaia/entity/apparel';

export interface StaticMeasurements {
  core: Partial<Record<string, { labels: Internationalization['label']; taxonomies: StaticMeasurementTaxonomy[] }>>;
  reference: Record<ApparelCategorySlug, ApparelCategorySlug[]>;
}

export interface StaticMeasurement {
  category: ApparelCategorySlug;
  label: Internationalization['label'];
  data: StaticMeasurementTaxonomy[];
}

export function createStaticMeasurement(slug: string, data?: StaticMeasurements['core'][string]): StaticMeasurement {
  return {
    category: slug as ApparelCategorySlug,
    label: data?.labels || {},
    data: data?.taxonomies || [],
  };
}

export enum StaticMeasurementTaxonomyType {
  Number = 'number',
  Textarea = 'textarea',
  Toggle = 'toggle',
  List = 'list',
  Dropdown = 'dropdown',
}

export interface StaticMeasurementTaxonomy {
  type: string | StaticMeasurementTaxonomyType;
  id: string;
  required: boolean;
  label: string;
  options: MeasurementOption[];
  isTaxonomy?: boolean;
}

export interface MeasurementOption {
  value: any;
  id?: string;
  label?: string;
  options?: {
    value: string;
  }[];
}

export const MEASUREMENT_NO_CHANGE_VALUE = 'no-change';
