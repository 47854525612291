// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { ApparelCategoryModelGroupXhr } from './apparel-category-model-group.xhr';

const ENTITY = 'APPAREL_CATEGORY_MODEL_GROUP';

export interface ApparelCategoryModelGroup extends IsaiaEntity<typeof ENTITY> {
  group: string;
  model: string;
}

export function createApparelCategoryModelGroup(options?: Partial<ApparelCategoryModelGroupXhr>): ApparelCategoryModelGroup {
  return markIsaiaEntity(ENTITY, {
    group: options?.group || '',
    model: options?.model || '',
  });
}

export function isInstanceOfApparelCategoryModelGroup(value: unknown): value is ApparelCategoryModelGroup {
  return isIsaiaEntity(value, ENTITY);
}
