import { inject } from '@angular/core';
import { createPopupButton, DialogService, PopupButtonEvent } from '@isaia/components/dialog';
import { Customer } from '@isaia/entity/customer';
import { translate } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';
import { SessionRepository } from './session.repository';
import { Session } from '@isaia/entity/session';
import { getCustomerFullName } from '@isaia/customer';

export function injectSessionDialog() {
  const dialogService = inject(DialogService);
  const sessionRepository = inject(SessionRepository);

  return {
    shouldOpenAddCustomerToSessionConfirmation: (customer: Customer) => {
      const allActions = { link: false, newSession: false };
      const linkedCustomer = sessionRepository.$customer();
      const alreadyLinkedResponse = { customerAlreadyLinked: true };
      if (!linkedCustomer?.id) {
        return Promise.resolve({ action: { ...allActions, link: true } } as PopupButtonEvent<typeof allActions>);
      }
      if (linkedCustomer?.id === customer.id) {
        return Promise.reject(alreadyLinkedResponse);
      }
      const currentCustomerName = getCustomerFullName(linkedCustomer);
      const newCustomerName = getCustomerFullName(customer);
      const popup$ = dialogService
        .openPopup({
          title: translate('popupAddCustomerToSessionTitle'),
          message: translate('popupAddCustomerToSessionMessage', {
            newName: newCustomerName,
            currentName: currentCustomerName,
          }),
          buttons: [
            createPopupButton({
              type: 'outline',
              label: translate('popupAddCustomerToSessionButtonReject', { currentName: currentCustomerName }),
            }),
            createPopupButton({
              label: translate('popupAddCustomerToSessionButtonConfirmCreateNewSession', { newName: newCustomerName }),
              type: 'flat',
              onTap: { ...allActions, newSession: true },
            }),
          ],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },

    openAddCustomerToSessionSuccess: () => {
      const popup$ = dialogService
        .openPopup({
          title: translate('popupAddCustomerToSessionSuccessTitle'),
          message: translate('popupAddCustomerToSessionSuccessMessage'),
          buttons: [createPopupButton({ label: translate('popupAddCustomerToSessionSuccessButtonOk') })],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },

    openDeleteSessionConfirmation: (sessionId: Session['id']) => {
      const message = sessionRepository.isCurrentSessionId(sessionId) ? 'popupDeleteCurrentSessionMessage' : 'popupDeleteSessionMessage';
      const popup$ = dialogService
        .openPopup({
          title: translate('popupDeleteSessionTitle'),
          message: translate(message),
          buttons: [
            createPopupButton({ label: translate('popupDeleteSessionButtonReject') }),
            createPopupButton({ label: translate('popupDeleteSessionButtonConfirm'), type: 'flat', onTap: { delete: true } }),
          ],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },

    shouldOpenUseSessionConfirmation: () => {
      const confirm = { use: true };
      if (!sessionRepository.$currentSessionId()) {
        return Promise.resolve({ action: confirm });
      }
      const popup$ = dialogService
        .openPopup({
          title: translate('popupUseSessionTitle'),
          message: translate('popupUseSessionMessage'),
          buttons: [
            createPopupButton({ label: translate('popupUseSessionButtonReject') }),
            createPopupButton({ label: translate('popupUseSessionButtonConfirm'), type: 'flat', onTap: confirm }),
          ],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },
  };
}
