import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  input,
  contentChildren,
  contentChild,
  effect,
  untracked,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { LabelComponent } from '@isaia/components/label';
import { merge, Subscription, tap } from 'rxjs';

@Component({
  selector: 'isa-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.direction]': 'direction()',
  },
})
export class FormFieldComponent {
  private readonly destroyRef = inject(DestroyRef);
  public direction = input<'row' | 'column'>();
  public ngControls = contentChildren(NgControl);
  public label = contentChild(LabelComponent);

  constructor() {
    let toggleSubscriptions: Subscription | undefined;

    effect(() => {
      const controls = this.ngControls();
      toggleSubscriptions?.unsubscribe();
      untracked(() => {
        toggleSubscriptions = this.toggleRequiredSymbol(controls);
      });
    });

    this.destroyRef.onDestroy(() => toggleSubscriptions?.unsubscribe());
  }

  private toggleRequiredSymbol(controls?: readonly NgControl[]) {
    if (!controls?.length) {
      return;
    }
    this.hasRequiredSymbol(controls);
    const changes$ = controls.map((i) => i.control?.statusChanges) || [];
    return merge(...changes$)
      .pipe(tap(() => this.hasRequiredSymbol(controls)))
      .subscribe();
  }

  private hasRequiredSymbol(queryList?: readonly NgControl[]) {
    const isVisible = queryList
      ? queryList.some((ctrl) => {
          const isRequired = this.hasRequiredField(ctrl.control);
          const isDisabled = ctrl?.disabled;
          return isRequired && !isDisabled;
        })
      : false;
    this.label()?.isRequiredSymbolVisible.set(isVisible);
  }

  private hasRequiredField(abstractControl?: AbstractControl | null): boolean {
    if (!abstractControl) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    if ((abstractControl as any).controls) {
      for (const controlName in (abstractControl as any).controls) {
        if ((abstractControl as any).controls[controlName]) {
          if (this.hasRequiredField((abstractControl as any).controls[controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
