import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpDeleteOptions, HttpGetOptions, HttpPostOptions, HttpPutOptions, HttpResponseWithPagination } from '@isaia/entity/http';
import { map } from 'rxjs';
import { SESSION_API_ORIGIN } from './session-api-origin.token';
import { createSession, Session, SessionXhr } from '@isaia/entity/session';
import { createId } from '@isaia/id';
import { clone } from '@isaia/clone';
import { SetOptional } from 'type-fest';

export enum GetSessionParams {
  FilterStore = 'filter[storeId]',
  FilterLockedBy = 'filter[lockedBy]',
}

type HttpGetSessionsResponse = HttpResponseWithPagination<SessionXhr[]>;
export type HttpGetSessionsOptions = HttpGetOptions<Partial<Record<GetSessionParams, string | number | null>>>;

type HttpGetSessionResponse = HttpResponseWithPagination<SessionXhr[]>;
export type HttpGetSessionOptions = HttpGetOptions;

export type HttpCreateSessionData = Partial<Session>;
type HttpCreateSessionResponse = HttpResponseWithPagination<SessionXhr[]>;

export type HttpUpdateSessionData = Omit<Session, 'createdAt' | '_entity'>;
type HttpUpdateSessionResponse = HttpResponseWithPagination<SessionXhr[]>;

@Injectable()
export class SessionApiService {
  private readonly http = inject(HttpClient);
  private readonly API_ORIGIN = inject(SESSION_API_ORIGIN);

  public getSessions(options?: HttpGetSessionsOptions) {
    return this.http.get<HttpGetSessionsResponse>(this.API_ORIGIN, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return {
          pagination: { ...response.pagination },
          sessions: response.data.map(createSession),
        };
      }),
    );
  }

  public getSession(sessionId: string, options?: HttpGetSessionOptions) {
    return this.http.get<HttpGetSessionResponse>(`${this.API_ORIGIN}/${sessionId}`, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createSession)[0];
      }),
    );
  }

  public createSession(data: HttpCreateSessionData, options?: HttpPostOptions) {
    const createdBy = data.createdBy;
    const authorCreatedBy: SessionXhr['createdBy'] | SessionXhr['updatedBy'] = {
      email: createdBy?.email || '',
      username: createdBy?.username || '',
      firstName: createdBy?.firstName || '',
      lastName: createdBy?.lastName || '',
    };
    const session: SetOptional<Omit<SessionXhr, 'createdAt'>, 'id' | 'customerId'> = {
      id: data.id || createId(),
      customerId: data.customerId,
      storeId: data.storeId || '',
      basket: clone(data.basket),
      createdById: authorCreatedBy.email,
      createdBy: authorCreatedBy,
      updatedById: authorCreatedBy.email,
      updatedBy: authorCreatedBy,
      lockedBy: data.lockedBy,
    };
    return this.http.post<HttpCreateSessionResponse>(`${this.API_ORIGIN}`, session, options as unknown as HttpPostOptions).pipe(
      map((response) => {
        return response.data.map(createSession)[0];
      }),
    );
  }

  public updateSession(data: HttpUpdateSessionData, options?: HttpPutOptions) {
    const createdBy = data.createdBy;
    const updatedBy = data.updatedBy;
    const session: SetOptional<Omit<SessionXhr, 'createdAt'>, 'customerId'> = {
      id: data.id,
      customerId: data.customerId,
      storeId: data.storeId,
      basket: data.basket,
      createdById: createdBy.email,
      createdBy: {
        email: createdBy.email,
        username: createdBy.username,
        firstName: updatedBy.firstName,
        lastName: updatedBy.lastName,
      },
      updatedById: updatedBy.email,
      updatedBy: {
        email: updatedBy.email,
        username: updatedBy.username,
        firstName: updatedBy.firstName,
        lastName: updatedBy.lastName,
      },
      lockedBy: data.lockedBy,
    };
    return this.http.put<HttpUpdateSessionResponse>(`${this.API_ORIGIN}/${data.id}`, session, options as unknown as HttpPutOptions).pipe(
      map((response) => {
        return response.data.map(createSession)[0];
      }),
    );
  }

  public deleteSession(sessionId: string, options?: HttpDeleteOptions) {
    return this.http.delete<void>(`${this.API_ORIGIN}/${sessionId}`, options as unknown as HttpDeleteOptions);
  }
}
