<label>
  <ng-content select="[isaInputPosition='before']"></ng-content>
  <input
    #inputRef
    [formControl]="ngControl.control"
    [name]="name()"
    [type]="type()"
    [placeholder]="placeholder()"
    [autocomplete]="autocomplete()"
    [attr.autocorrect]="autocorrect()"
    [attr.pattern]="pattern()"
    [autocapitalize]="autocapitalize()"
    [spellcheck]="spellcheck()"
  />
  <ng-content select="[isaInputPosition='after']"></ng-content>
</label>

@if (error.$isInvalid()) {
  <isa-form-field-error [errors]="error.$errors()" [customErrors]="customErrors()" />
}
