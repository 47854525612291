<isa-modal *transloco="let translate" [title]="data.modalTitle || translate('emailContentModalTitle')" [hasClosableHeader]="true">
  <fieldset>
    <isa-form-field>
      <isa-label>{{ translate('emailContentModalSubjectInputLabel') }}</isa-label>
      <isa-input [formControl]="form.controls.subject" [placeholder]="translate('emailContentModalSubjectInputPlaceholder')" />
    </isa-form-field>
    <isa-form-field>
      <isa-label>{{ translate('emailContentModalBodyInputLabel') }}</isa-label>
      <textarea [formControl]="form.controls.body" [placeholder]="translate('emailContentModalBodyInputPlaceholder')"></textarea>
    </isa-form-field>
  </fieldset>
  <isa-button (click)="close()" [disabled]="!form.valid" type="flat">{{ translate('emailContentModalBodySaveButton') }}</isa-button>
</isa-modal>
