import { Directive, inject } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, FormControlName, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'noopNgControl',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoopValueAccessorDirective,
    },
  ],
})
export class NoopValueAccessorDirective implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public writeValue() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnChange() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched() {}
}

export function injectNgControl() {
  const ngControl = inject(NgControl, { self: true, optional: true });

  if (!ngControl) {
    throw new Error('Unable to inject injectNgControl');
  }

  if (ngControl instanceof NgModel) {
    throw new Error('NgModel is not supported in injectNgControl');
  }

  if (ngControl instanceof FormControlDirective || ngControl instanceof FormControlName) {
    return ngControl;
  }

  throw new Error('Unable to create injectNgControl');
}
