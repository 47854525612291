import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ElementRef,
  AfterContentInit,
  inject,
  contentChild,
  input,
  computed,
} from '@angular/core';
import { ModalHeaderDirective } from './modal-header.directive';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ButtonCancelComponent } from '@isaia/components/button';

@Component({
  selector: 'isa-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonCancelComponent],
})
export class ModalComponent implements AfterContentInit {
  private readonly elementRef = inject(ElementRef);
  public readonly dialogRef: MatDialogRef<unknown, unknown> = inject(MatDialogRef);

  public hideHeader = input<boolean | undefined>();
  public title = input<string>();
  public hasClosableHeader = input(false);
  public closeHeaderAction = input<() => void>();
  public modalHeader = contentChild(ModalHeaderDirective, { read: ElementRef });
  public hasHeader = computed(() => !this.hideHeader() && !this.modalHeader());

  public ngAfterContentInit() {
    firstValueFrom(this.dialogRef.afterOpened()).then(() => {
      this.shouldAddScrollableClass();
    });
  }

  public closeHeader() {
    const action = this.closeHeaderAction();
    action ? action() : this.dialogRef.close();
  }

  private shouldAddScrollableClass() {
    const wrapper = this.elementRef.nativeElement.closest('.isa-dialog') as HTMLElement;
    const hasScroll = wrapper.scrollHeight - this.elementRef.nativeElement.scrollHeight < 0;
    wrapper.classList.toggle('isa-dialog--patch-scroll', hasScroll);
  }
}
