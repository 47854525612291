// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { PriceXhr } from './price.xhr';

const ENTITY = 'PRICE';

export const PRICE_RANGE_PREFIX = '000';

export interface Price extends IsaiaEntity<typeof ENTITY> {
  id: number;
  group: `${typeof PRICE_RANGE_PREFIX}${string}`;
  price: string;
  season: string;
  priceListId: string;
  priceCategoryCode: string;
  modelGroup: string;
  currency: string;
  symbol: string;
}

export function createPrice(options: Partial<PriceXhr>): Price {
  return markIsaiaEntity(ENTITY, {
    id: options.id || 0,
    group: options.pricingGroup || PRICE_RANGE_PREFIX,
    price: options.price || '',
    season: options.season,
    priceListId: options.priceListId,
    priceCategoryCode: options.categoryPriceCode,
    modelGroup: options.modelGroup,
    currency: options?.currency?.name || 'INVALID-SERVER-CURRENCY',
    symbol: options?.currency?.value || '',
  });
}

export function isInstanceOfPrice(value: unknown): value is Price {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
