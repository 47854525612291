import type { CalculatePriceStrategy } from './price-calculator.interface';

export const getPriceByStyleMaterial: CalculatePriceStrategy = (options) => {
  return {
    calculate() {
      const sku = options.taxonomyValue?.['sku'] as string | undefined;
      if (!sku) {
        return undefined;
      }

      const [model, group] = sku.split(' ');
      if (!model || !group) {
        return undefined;
      }

      return options.prices?.find((price) => {
        return price.modelGroup === model && price.group === group;
      });
    },

    reset() {
      const { taxonomyValue, taxonomyRequiredToCalculatePrice } = options;
      return !!taxonomyRequiredToCalculatePrice?.some((t) => !taxonomyValue?.[t.slug]);
    },
  };
};
