import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ButtonColor, ButtonComponent } from '../button.component';
import { INPUT_TAB_INDEX } from '../button.helper';

@Component({
  selector: 'isa-button-cancel',
  imports: [ButtonComponent],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <isa-button [tabindex]="tabindex()" type="mini-fab" [color]="color()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
        <path
          d="M480-442.847 309.076-271.924q-8.307 8.308-17.884 8-9.576-.307-18.268-9-8.693-8.692-8.693-18.576t8.693-18.576L442.847-480 271.924-650.924q-8.308-8.307-8-18.384.307-10.076 9-18.768 8.692-8.693 18.576-8.693t18.576 8.693L480-517.153l170.924-170.923q8.307-8.308 18.384-8.5 10.076-.193 18.768 8.5 8.693 8.692 8.693 18.576t-8.693 18.576L517.153-480l170.923 170.924q8.308 8.307 8.5 17.884.193 9.576-8.5 18.268-8.692 8.693-18.576 8.693t-18.576-8.693L480-442.847Z"
        />
      </svg>
    </isa-button>
  `,
})
export class ButtonCancelComponent {
  public tabindex = input(INPUT_TAB_INDEX.INITIAL_VALUE, { alias: 'focusOnTab', transform: INPUT_TAB_INDEX.TRANSFORM });
  public color = input<ButtonColor>('transparent');
}
