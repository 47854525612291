import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APPAREL_API_ORIGIN } from './apparel-api-origin.token';
import { map } from 'rxjs';
import { HttpGetOptions, HttpPaginationParams, HttpResponseWithPagination, HttpSortable } from '@isaia/entity/http';
import { ApparelCategoryXhr, createApparelCategory } from '@isaia/entity/apparel';

export interface HttpGetCategoryQueryParams extends HttpPaginationParams {
  'filter[slug]'?: number;
  sort?: HttpSortable<'id' | 'slug' | 'outLimit'>; // example, sort=+id/-id
}

export type HttpGetCategoriesOptions = HttpGetOptions<HttpGetCategoryQueryParams>;

@Injectable()
export class ApparelApiService {
  private readonly http = inject(HttpClient);
  private readonly API_ORIGIN = inject(APPAREL_API_ORIGIN);

  public getCategories(options?: HttpGetCategoriesOptions) {
    const url = `${this.API_ORIGIN}/categories`;
    return this.http.get<HttpResponseWithPagination<ApparelCategoryXhr[]>>(url, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createApparelCategory);
      }),
    );
  }
}
