import { inject, Injectable } from '@angular/core';
import { SessionRepository } from './session.repository';
import { Session, SessionClientAdvisor, SessionProduct } from '@isaia/entity/session';
import { Customer } from '@isaia/entity/customer';
import { createId } from '@isaia/id';
import { injectSessionDialog } from './session.dialog';
import { HttpGetSessionOptions, HttpGetSessionsOptions, SessionApiService } from '@isaia/session';
import { AuthRepository, getUserFullName } from '../auth';
import { finalize, firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { GeographyRepository } from '../geography';
import { ApplicationRepository } from '../application';
import { CustomerRepository, CustomerService } from '../customer';
import { SetRequired } from 'type-fest';
import { keyBy } from 'lodash-es';
import { withErrorPopup } from '../http';
import { HttpErrorCode, HttpErrorException, HttpPostOptions } from '@isaia/entity/http';
import { ERROR_MESSAGE } from '../error';
import { translate } from '@jsverse/transloco';

export interface SessionHydrated extends Session {
  customer?: Customer;
}

@Injectable()
export class SessionService {
  private readonly applicationRepository = inject(ApplicationRepository);
  private readonly customerRepository = inject(CustomerRepository);
  private readonly customerService = inject(CustomerService);
  private readonly sessionApiService = inject(SessionApiService);
  private readonly sessionRepository = inject(SessionRepository);
  private readonly geographyRepository = inject(GeographyRepository);
  private readonly authRepository = inject(AuthRepository);
  private readonly sessionDialog = injectSessionDialog();

  public getSessions(options?: HttpGetSessionsOptions) {
    return this.sessionApiService.getSessions(options);
  }

  public getSession(sessionId: string, options?: HttpGetSessionOptions) {
    this.applicationRepository.setLoading(true);
    return this.sessionApiService.getSession(sessionId, options).pipe(
      finalize(() => {
        this.applicationRepository.setLoading(false);
      }),
    );
  }

  public getSessionHydrated(sessionId: string, options?: HttpGetSessionOptions): Observable<SessionHydrated> {
    this.applicationRepository.setLoading(true);
    return this.getSession(sessionId, options).pipe(
      switchMap((session) => {
        const customerId = session.customerId;
        if (!customerId) {
          return of(session);
        }
        return this.customerService.getCustomer(customerId).pipe(
          map((customer) => {
            return { ...session, customer };
          }),
        );
      }),
      finalize(() => {
        this.applicationRepository.setLoading(false);
      }),
    );
  }

  public getSessionsHydrated(options?: HttpGetSessionsOptions): Observable<SessionHydrated[]> {
    this.applicationRepository.setLoading(true);
    return this.getSessions(options).pipe(
      switchMap(({ sessions }) => {
        const customerIds = sessions.map((s) => s.customerId).filter((id): id is string => !!id);
        const uniqueCustomerIds = Array.from(new Set(customerIds));
        if (!uniqueCustomerIds?.length) {
          return of(sessions);
        }
        return this.customerService.getCustomersByIds(uniqueCustomerIds).pipe(
          map((customers) => {
            const customersKeyed = keyBy(customers, 'id');
            return sessions.map((s): SessionHydrated => {
              const customer = s.customerId ? customersKeyed[s.customerId] : undefined;
              if (customer) {
                return { ...s, customer: customer };
              }
              return s;
            });
          }),
        );
      }),
      finalize(() => this.applicationRepository.setLoading(false)),
    );
  }

  private createSession(session: SetRequired<Partial<Session>, 'customerId'>) {
    const user = this.authRepository.$user()!;
    this.applicationRepository.setLoading(true);
    const author: SessionClientAdvisor = { email: user.email, username: user.username, firstName: user.firstName, lastName: user.lastName };
    return this.sessionApiService
      .createSession({
        id: createId(),
        customerId: session.customerId,
        basket: session.basket || {},
        createdBy: author,
        updatedBy: author,
        storeId: this.geographyRepository.$currentStore().id,
        lockedBy: user.username,
      })
      .pipe(
        tap((res) => {
          this.sessionRepository.setCurrentSession(res);
        }),
        finalize(() => {
          this.applicationRepository.setLoading(false);
        }),
      );
  }

  private updateSession(
    updateWith: Partial<Pick<Session, 'basket' | 'customerId' | 'lockedBy'>> | ((currentSession: Session) => Partial<Session>),
    baseSession = this.sessionRepository.$currentSession(),
    options?: HttpPostOptions,
  ) {
    const user = this.authRepository.$user()!;
    const dataToUpdate = typeof updateWith === 'function' ? updateWith(baseSession) : updateWith;
    this.applicationRepository.setLoading(true);
    return this.sessionApiService
      .updateSession(
        {
          id: baseSession.id,
          basket: {
            items: dataToUpdate.basket?.items ? dataToUpdate.basket?.items : baseSession.basket?.items || [],
          },
          storeId: baseSession.storeId,
          customerId: dataToUpdate.customerId || baseSession.customerId,
          createdBy: baseSession.createdBy,
          updatedBy: { email: user.email, username: user.username, firstName: user.firstName, lastName: user.lastName },
          lockedBy: 'lockedBy' in dataToUpdate ? dataToUpdate.lockedBy : user.username,
        },
        {
          context: withErrorPopup({ debug: false }),
          ...options,
        },
      )
      .pipe(
        tap((res) => {
          this.sessionRepository.setCurrentSession(res);
        }),
        finalize(() => {
          this.applicationRepository.setLoading(false);
        }),
      );
  }

  public addCustomerToSession(customer: Customer, options?: { showConfirmationDialog?: boolean }) {
    const customerId = customer.id;
    return this.sessionDialog
      .shouldOpenAddCustomerToSessionConfirmation(customer)
      .then((res) => {
        if (res?.action?.link || res?.action?.newSession) {
          const shouldReleaseCurrentSession = res.action.newSession ? this.releaseCurrentSession() : Promise.resolve();
          return shouldReleaseCurrentSession
            .then(() => firstValueFrom(this.createSession({ customerId })))
            .then(() => {
              // todo: capire come migliorare il linkaggio del customer
              this.customerRepository.addCustomer(customer);
              return options?.showConfirmationDialog !== false
                ? this.sessionDialog.openAddCustomerToSessionSuccess()
                : Promise.resolve(undefined);
            });
        }

        return Promise.reject();
      })
      .catch((e) => {
        if (e?.customerAlreadyLinked) {
          return Promise.resolve();
        }
        throw e;
      });
  }

  public addProductToBasket(product: SessionProduct) {
    return this.updateSession((currentSession) => {
      return {
        basket: {
          items: [...(currentSession.basket?.items || []), product],
        },
      };
    });
  }

  public editProductInBasket(product: SessionProduct) {
    return this.updateSession((currentSession) => {
      return {
        basket: {
          items: currentSession.basket?.items?.map((i) => {
            return i.id === product.id ? product : i;
          }),
        },
      };
    });
  }

  public removeProductToBasket(productId: SessionProduct['id']) {
    return this.updateSession((currentSession) => {
      return {
        basket: {
          items: currentSession.basket?.items?.filter((i) => i.id !== productId),
        },
      };
    });
  }

  public releaseCurrentSession() {
    this.applicationRepository.setLoading(true);
    return firstValueFrom(this.updateSession({ lockedBy: null }))
      .then(() => {
        this.sessionRepository.releaseCurrentSession();
      })
      .finally(() => {
        this.applicationRepository.setLoading(false);
      });
  }

  public deleteSession(sessionId: Session['id'], options?: { showConfirmationDialog: boolean }) {
    const confirmation =
      options?.showConfirmationDialog !== false
        ? this.sessionDialog.openDeleteSessionConfirmation(sessionId)
        : Promise.resolve({ action: { delete: true } });
    return confirmation.then((res) => {
      if (res?.action?.delete) {
        const shouldReleaseSession = this.sessionRepository.isCurrentSessionId(sessionId)
          ? this.releaseCurrentSession()
          : Promise.resolve();
        this.applicationRepository.setLoading(true);
        return shouldReleaseSession
          .then(() => {
            return this.ensureIsSessionEntity(sessionId).then((session) => {
              const delete$ = this.sessionApiService.deleteSession(sessionId, { context: this.showErrorPopupWithSessionInfo(session) });
              return firstValueFrom(delete$);
            });
          })
          .finally(() => {
            this.applicationRepository.setLoading(false);
          });
      }
      return Promise.reject();
    });
  }

  private showErrorPopupWithSessionInfo(session: Session) {
    return withErrorPopup({
      debug: false,
      message: (e) => {
        const code = (e?.error as HttpErrorException)?.error?.code as
          | HttpErrorCode.SessionLockedException
          | HttpErrorCode.SessionLockedStoreException;
        const fullName = getUserFullName(session.updatedBy) || getUserFullName(session.createdBy);
        const storeName = this.geographyRepository.getStore(session.storeId).name;
        return code ? ERROR_MESSAGE[code](translate, { fullName, storeName }) : '';
      },
    });
  }

  private ensureIsSessionEntity(sessionIdOrSession: Session['id'] | Session) {
    return typeof sessionIdOrSession === 'string'
      ? firstValueFrom(this.getSession(sessionIdOrSession))
      : Promise.resolve(sessionIdOrSession);
  }

  public useSession(sessionIdOrSession: Session['id'] | Session, customer: Customer) {
    return this.sessionDialog.shouldOpenUseSessionConfirmation().then((res) => {
      if (res?.action?.use) {
        return this.ensureIsSessionEntity(sessionIdOrSession)
          .then((session) => {
            const update$ = this.updateSession({ lockedBy: this.authRepository.$user()?.username }, session, {
              context: this.showErrorPopupWithSessionInfo(session),
            });
            return firstValueFrom(update$);
          })
          .then((updated) => {
            this.sessionRepository.setCurrentSession(updated);
            this.customerRepository.addCustomer(customer);
          });
      }
      return Promise.resolve();
    });
  }
}
