import { Injectable, signal } from '@angular/core';
import { createStaticMeasurement, StaticMeasurement, StaticMeasurements } from '@isaia/entity/measurement';
import { ApparelCategorySlug } from '@isaia/entity/apparel';

@Injectable()
export class MeasurementRepository {
  private readonly state = {
    staticMeasurements: signal<StaticMeasurements | undefined>(undefined),
  };

  public staticMeasurements = this.state.staticMeasurements.asReadonly();

  public setStaticMeasurements(data?: StaticMeasurements) {
    this.state.staticMeasurements.set(data);
  }

  public getCategoryMeasurements(slug?: ApparelCategorySlug | string): StaticMeasurement[] {
    if (slug) {
      const data = this.staticMeasurements();
      const referencesKeys = ((data?.reference as any)[slug] as ApparelCategorySlug[]) || [];
      return referencesKeys.map((slug) => createStaticMeasurement(slug, data?.core[slug])) || [];
    }
    return [];
  }

  public getBaseMeasurement(slug?: ApparelCategorySlug | string): StaticMeasurement | undefined {
    if (!slug) {
      return undefined;
    }
    const data = this.staticMeasurements();
    return createStaticMeasurement(slug, data?.core[slug]);
  }

  public getBaseMeasurements(): StaticMeasurement[] {
    const core = this.staticMeasurements()?.core;
    if (!core) {
      return [];
    }
    return Object.keys(core).map((slug) => createStaticMeasurement(slug, core[slug]));
  }
}
