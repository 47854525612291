// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { createEntityInternalizationLabel, Internationalization } from '@isaia/entity/internationalization';
import { ApparelCategoryTaxonomy, createApparelCategoryTaxonomy } from './apparel-category-taxonomy.model';
import { ApparelCategoryXhr } from './apparel-category.xhr';
import { ApparelCategoryModelGroup, createApparelCategoryModelGroup } from './apparel-category-model-group.model';
import { Simplify } from 'type-fest';
import { ApparelCategoryPricingRole } from './apparel-category-pricing-role.model';

const ENTITY = 'APPAREL_CATEGORY';

export interface ApparelCategory extends IsaiaEntity<typeof ENTITY>, Internationalization {
  id: string | number;
  taxonomies: ApparelCategoryTaxonomy[];
  section: Simplify<Internationalization & { id: number | string; area: string }>;
  consumption: number;
  priceCategoryCodes: string[];
  modelGroups: ApparelCategoryModelGroup[];
  fabricCategoryCode: number[];
  pricingRole: ApparelCategoryPricingRole;
}

export function createApparelCategory(options?: Partial<ApparelCategoryXhr>): ApparelCategory {
  const taxonomies = options?.taxonomies?.map(createApparelCategoryTaxonomy) || [];
  const sectionXhr = options?.section;
  return markIsaiaEntity(ENTITY, {
    id: options?.id || NaN,
    taxonomies,
    section: {
      id: sectionXhr?.id || 'INVALID-SERVER-ID',
      slug: sectionXhr?.sectionSlug || '',
      label: createEntityInternalizationLabel(sectionXhr?.sectionLabels),
      area: sectionXhr?.area || 'unkown area',
    },
    consumption: options?.consumption || 0,
    label: createEntityInternalizationLabel(options?.categoryLabels),
    slug: options?.categorySlug || '',
    priceCategoryCodes: options?.priceCategoryCodes || [],
    modelGroups: options?.modelGroups?.map(createApparelCategoryModelGroup),
    fabricCategoryCode: options?.fabricCategoryCodes || [],
    pricingRole: options?.pricingRules,
  });
}

export function isInstanceOfApparelCategory(value: unknown): value is ApparelCategory {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}

export type ApparelCategoriesKeyed = Record<ApparelCategory['id'], ApparelCategory>;
