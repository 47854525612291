import { DialogService } from './dialog.service';
import { ComponentType } from '@angular/cdk/portal';
import { inject } from '@angular/core';
import { Modal } from './modal';

export function injectableModal<T extends Modal>(component: ComponentType<T>) {
  const dialogService = inject(DialogService);

  return {
    open: (options?: Parameters<typeof DialogService.prototype.openModal<T>>[1]) => {
      return dialogService.openModal(component, options);
    },
  };
}
